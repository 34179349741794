import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
      dark: "#303f9f",
      light: "#7986cb"
    },
    secondary: {
      main: "#f50057",
      dark: "#c51162",
      light: "#ff4081"
    }
  },
  typography: {
    fontSize: 12, // Reduz o tamanho padrão da fonte
    h4: {
      fontSize: '1.5rem', // Tamanho menor para títulos h4
    },
    h6: {
      fontSize: '1rem', // Tamanho menor para títulos h6
    },
    body1: {
      fontSize: '0.875rem', // Tamanho menor para textos normais
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '10px', // Menos espaçamento dentro dos cards
          margin: '5px', // Menos espaçamento entre os cards
          minHeight: '120px', // Altura mínima dos cards ajustada
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '5px 10px', // Botões mais compactos
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: '10px', // Menos margem entre os textos
        }
      }
    }
  },
  spacing: 4, // Ajuste de espaçamento menor para grids e outros componentes
});

export default theme;
