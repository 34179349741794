import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import { AuthContext } from "./AuthContext"; // Ajuste o caminho conforme necessário
import { db } from "./firebase"; // Ajuste conforme necessário
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs
} from "firebase/firestore";
import ImpressaoEtiqueta from "./ImpressaoEtiqueta"; // Ajuste o caminho conforme necessário

const Etiquetas = () => {
  const { userDetails } = useContext(AuthContext);
  const [codigoProduto, setCodigoProduto] = useState("");
  const [quantidadeImpressoes, setQuantidadeImpressoes] = useState(1);
  const [produto, setProduto] = useState(null);
  const [erro, setErro] = useState("");
  const [produtosSugeridos, setProdutosSugeridos] = useState([]);

  useEffect(() => {
    const buscarProdutosSugeridos = async () => {
      if (!codigoProduto) {
        setProdutosSugeridos([]);
        return;
      }

      const produtosRef = collection(
        db,
        "EMPRESAS",
        userDetails.empresaId,
        "Cadastro de Peças",
        "dados",
        "Estoque Ativo"
      );

      const q = query(
        produtosRef,
        where("codigoPeca", ">=", codigoProduto),
        where("codigoPeca", "<=", codigoProduto + "\uf8ff"),
        orderBy("codigoPeca"),
        limit(5)
      );

      const q2 = query(
        produtosRef,
        where("descricao", ">=", codigoProduto.toUpperCase()),
        where("descricao", "<=", codigoProduto.toUpperCase() + "\uf8ff"),
        orderBy("descricao"),
        limit(5)
      );

      const [querySnapshot1, querySnapshot2] = await Promise.all([
        getDocs(q),
        getDocs(q2)
      ]);
      const produtosEncontrados1 = querySnapshot1.docs.map(doc => doc.data());
      const produtosEncontrados2 = querySnapshot2.docs.map(doc => doc.data());
      const produtosEncontrados = [
        ...produtosEncontrados1,
        ...produtosEncontrados2
      ];
      setProdutosSugeridos(produtosEncontrados);
    };

    buscarProdutosSugeridos();
  }, [codigoProduto, userDetails.empresaId]);

  const handleSelecionarSugestao = sugestao => {
    setCodigoProduto(sugestao.codigoPeca);
    setProdutosSugeridos([]); // Limpa as sugestões após a seleção
  };

  const buscarProdutoPorCodigo = async () => {
    if (!codigoProduto) {
      setErro("Por favor, insira um código de produto válido.");
      return;
    }

    const produtosRef = collection(
      db,
      "EMPRESAS",
      userDetails.empresaId,
      "Cadastro de Peças",
      "dados",
      "Estoque Ativo"
    );
    const q = query(produtosRef, where("codigoPeca", "==", codigoProduto));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const produtoEncontrado = querySnapshot.docs[0].data();
      setProduto(produtoEncontrado);
      setErro("");
    } else {
      setErro("Produto não encontrado.");
      setProduto(null);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        style={{ padding: "20px", marginTop: "20px", marginBottom: "20px" }}
      >
        <Typography variant="h5" style={{ marginBottom: "20px" }}>
          Buscar Produto para Impressão de Etiquetas
        </Typography>
        <TextField
          label="Código ou Descrição"
          variant="outlined"
          fullWidth
          value={codigoProduto}
          onChange={e => setCodigoProduto(e.target.value)}
          error={!!erro}
          helperText={erro}
          style={{ marginBottom: "20px" }}
        />
        {/* Exibe as sugestões de produtos */}
        {produtosSugeridos.length > 0 && (
          <Paper style={{ maxHeight: 200, overflow: "auto", marginBottom: "20px" }}>
            <List>
              {produtosSugeridos.map((produto, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => handleSelecionarSugestao(produto)}
                >
                  <ListItemText
                    primary={`${produto.codigoPeca} - ${produto.descricao}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        <TextField
          label="Quantidade de Impressões"
          variant="outlined"
          fullWidth
          type="number"
          value={quantidadeImpressoes}
          onChange={e => setQuantidadeImpressoes(e.target.value)}
          inputProps={{ min: 1 }}
          style={{ marginBottom: "20px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={buscarProdutoPorCodigo}
          fullWidth
        >
          Buscar e Imprimir Etiqueta
        </Button>
      </Paper>
      {produto && (
        <ImpressaoEtiqueta
          codigo={produto.codigoPeca}
          descricao={produto.descricao}
          tamanho={produto.tamanho}
          marca={produto.marca}
          categoria={produto.categoria}
          valorVenda={produto.valorVenda}
          quantidade={quantidadeImpressoes} // Certifique-se de que o componente ImpressaoEtiqueta suporte e processe esta propriedade
        />
      )}
    </Container>
  );
};

export default Etiquetas;
