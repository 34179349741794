import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Container,
  TableRow,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Box,
  Button,
  Tooltip
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Link as LinkIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  PictureAsPdf as PictureAsPdfIcon
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import Collapse from "@mui/material/Collapse";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const formatCurrency = value => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);
};

function DetalhesPagamentoFornecedor() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState("");
  const [pagamentos, setPagamentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchMonth, setSearchMonth] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;

  useEffect(() => {
    carregarFornecedores();
  }, [empresaId]);

  useEffect(() => {
    if (fornecedorSelecionado) {
      carregarPagamentos(fornecedorSelecionado);
    }
  }, [fornecedorSelecionado]);

  const [expandedRowId, setExpandedRowId] = useState(null);

  const handleToggleRow = id => {
    setExpandedRowId(expandedRowId === id ? null : id); // Toggle
  };

  const carregarFornecedores = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(
        collection(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores`)
      );
      const fornecedores = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setFornecedores(fornecedores);
    } catch (error) {
      console.error("Erro ao buscar fornecedores:", error);
    }
    setLoading(false);
  };

  const carregarPagamentos = async fornecedorId => {
    setLoading(true);
    try {
      let q = query(
        collection(
          db,
          `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Pagamentos`
        ),
        where("statusPagamento", "==", "PAGO")
      );

      if (searchTerm) {
        q = query(q, where("idPagamento", "==", searchTerm));
      }

      const querySnapshot = await getDocs(q);
      let pagamentos = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      if (searchMonth) {
        const startOfMonth = new Date(
          searchMonth.getFullYear(),
          searchMonth.getMonth(),
          1
        );
        const endOfMonth = new Date(
          searchMonth.getFullYear(),
          searchMonth.getMonth() + 1,
          0
        );
        pagamentos = pagamentos.filter(pagamento => {
          const dataPagamento = pagamento.dataPagamento.toDate();
          return dataPagamento >= startOfMonth && dataPagamento <= endOfMonth;
        });
      }

      if (searchValue) {
        pagamentos = pagamentos.filter(
          pagamento => pagamento.totalPago >= parseFloat(searchValue)
        );
      }

      setPagamentos(pagamentos);
    } catch (error) {
      console.error("Erro ao buscar pagamentos:", error);
    }
    setLoading(false);
  };

  const handleViewAttachment = url => {
    window.open(url, "_blank"); // Abre a URL do anexo em uma nova guia
  };

  const generatePDF = pagamento => {
    const doc = new jsPDF();

    const tableColumn = [
      "Produto",
      "Quantidade",
      "Preço Final",
      "Repasse ao Fornecedor"
    ];
    const tableRows = [];

    pagamento.vendasPagas.forEach(venda => {
      venda.produtos.forEach(produto => {
        const produtoData = [
          produto.descricao,
          formatCurrency(produto.valorVenda),
          produto.quantidade,
          formatCurrency(produto.precoFinal),
          formatCurrency(produto.repasseFornecedor)
        ];
        tableRows.push(produtoData);
      });
    });

    doc.text("Detalhes do Pagamento", 14, 15);
    doc.text(
      `Data do Pagamento: ${pagamento.dataPagamento
        .toDate()
        .toLocaleDateString("pt-BR")}`,
      14,
      25
    );
    doc.text(`ID do Pagamento: ${pagamento.idPagamento}`, 14, 35);
    doc.text(`Método de Pagamento: ${pagamento.metodoPagamento}`, 14, 45);
    doc.text(`Observação: ${pagamento.observacao}`, 14, 55);

    // Adicionando a tabela
    doc.autoTable(tableColumn, tableRows, { startY: 65 });
    doc.save(`pagamento_${pagamento.idPagamento}.pdf`);
  };

  const calcularTotalRepassado = pagamentos => {
    let total = 0;
    if (Array.isArray(pagamentos)) {
      pagamentos.forEach(pagamento => {
        if (pagamento.vendasPagas && Array.isArray(pagamento.vendasPagas)) {
          pagamento.vendasPagas.forEach(venda => {
            if (venda.produtos && Array.isArray(venda.produtos)) {
              venda.produtos.forEach(produto => {
                total += parseFloat(produto.repasseFornecedor) || 0;
              });
            }
          });
        }
      });
    }
    return total;
  };

  const totalRepassado = calcularTotalRepassado(pagamentos);
  const mediaValorPago = totalRepassado / (pagamentos.length || 1);


  const calcularRepasseTotalPorPagamento = pagamento => {
    let totalRepasse = 0;
    if (pagamento.vendasPagas && Array.isArray(pagamento.vendasPagas)) {
      pagamento.vendasPagas.forEach(venda => {
        if (venda.produtos && Array.isArray(venda.produtos)) {
          venda.produtos.forEach(produto => {
            totalRepasse += Number(produto.repasseFornecedor || 0);
          });
        }
      });
    }
    return totalRepasse; // Formata o número para duas casas decimais
  };

 

  const handleSearch = () => {
    if (fornecedorSelecionado) {
      carregarPagamentos(fornecedorSelecionado);
    }
  };

    return (
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            minHeight: "100vh",
            paddingBottom: "20px"
          }}
        >
              <Container
                maxWidth="xl"
                style={{
                  marginTop: "20px",
                  paddingLeft: isMdUp ? "220px" : "16px",
                  paddingRight: "16px",
                  maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
                }}
              >
            <Typography
              variant="h5" // De h4 para h5
              sx={{
                marginBottom: "16px", // Reduzindo marginBottom
                color: theme.palette.primary.dark,
                fontWeight: "bold",
                textAlign: "center",
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)"
              }}
            >
              Extratos de Pagamentos ao Fornecedor
            </Typography>
            <Card
              elevation={6}
              sx={{
                padding: "16px", // Reduzindo padding
                backgroundColor: "#ffffff",
                borderRadius: "8px", // Reduzindo borderRadius
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                marginBottom: "16px", // Reduzindo marginBottom
              }}
            >
              <FormControl fullWidth sx={{ marginBottom: "16px" }}> {/* Reduzindo marginBottom */}
                <InputLabel id="fornecedor-select-label">Fornecedor</InputLabel>
                <Select
                  labelId="fornecedor-select-label"
                  id="fornecedor-select"
                  value={fornecedorSelecionado}
                  label="Fornecedor"
                  onChange={e => setFornecedorSelecionado(e.target.value)}
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.palette.primary.main
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary.dark
                      }
                    }
                  }}
                >
                  {fornecedores.map(fornecedor => (
                    <MenuItem key={fornecedor.id} value={fornecedor.id}>
                      {fornecedor.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid container spacing={2} sx={{ marginBottom: "16px" }}> {/* Reduzindo marginBottom */}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Pesquisar por ID de Pagamento"
                    variant="outlined"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: theme.palette.primary.main
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.primary.dark
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      views={["year", "month"]}
                      label="Pesquisar por Mês"
                      value={searchMonth}
                      onChange={newValue => setSearchMonth(newValue)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: theme.palette.primary.main
                              },
                              "&:hover fieldset": {
                                borderColor: theme.palette.primary.dark
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Pesquisar por Valor"
                    variant="outlined"
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: theme.palette.primary.main
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.primary.dark
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      padding: "8px 0", // Reduzindo padding
                      fontWeight: "bold",
                      textTransform: "none"
                    }}
                  >
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "16px" }}> {/* Reduzindo marginBottom */}
                <Grid item xs={12} md={4}>
                  <Card
                    elevation={3}
                    sx={{
                      backgroundColor: theme.palette.success.light,
                      color: theme.palette.success.contrastText,
                      padding: "16px", // Reduzindo padding
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <CardContent sx={{ padding: "8px" }}> {/* Diminuindo padding */}
                      <Typography variant="subtitle1" gutterBottom> {/* De h6 para subtitle1 */}
                        Total Pago
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}> {/* De h4 para h6 */}
                        {formatCurrency(totalRepassado)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card
                    elevation={3}
                    sx={{
                      backgroundColor: theme.palette.info.light,
                      color: theme.palette.info.contrastText,
                      padding: "16px", // Reduzindo padding
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <CardContent sx={{ padding: "8px" }}> {/* Diminuindo padding */}
                      <Typography variant="subtitle1" gutterBottom> {/* De h6 para subtitle1 */}
                        Total de Pagamentos
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}> {/* De h4 para h6 */}
                        {pagamentos.length}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card
                    elevation={3}
                    sx={{
                      backgroundColor: theme.palette.warning.light,
                      color: theme.palette.warning.contrastText,
                      padding: "16px", // Reduzindo padding
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <CardContent sx={{ padding: "8px" }}> {/* Diminuindo padding */}
                      <Typography variant="subtitle1" gutterBottom> {/* De h6 para subtitle1 */}
                        Média de Valor Pago
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}> {/* De h4 para h6 */}
                        {formatCurrency(mediaValorPago)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Table
                  sx={{ mt: 2, backgroundColor: "#ffffff", borderRadius: "8px" }}
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                        Data do Pagamento
                      </TableCell>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                        ID do Pagamento
                      </TableCell>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                        Método de Pagamento
                      </TableCell>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                        Observação
                      </TableCell>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                        Total pago
                      </TableCell>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                        Anexo
                      </TableCell>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                        Reimprimir PDF
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pagamentos.length > 0 ? (
                      pagamentos.map(pagamento => (
                        <React.Fragment key={pagamento.id}>
                          <TableRow
                            hover
                            sx={{
                              "&:hover": { backgroundColor: "#e0f2f1" },
                              transition: "background-color 0.3s"
                            }}
                          >
                            <TableCell>
                              {pagamento.dataPagamento
                                ? pagamento.dataPagamento
                                    .toDate()
                                    .toLocaleDateString("pt-BR")
                                : ""}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Expandir/Colapsar detalhes">
                                <IconButton
                                  onClick={() => handleToggleRow(pagamento.id)}
                                >
                                  {expandedRowId === pagamento.id ? (
                                    <ExpandLessIcon />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                              {pagamento.idPagamento}
                            </TableCell>
                            <TableCell>{pagamento.metodoPagamento}</TableCell>
                            <TableCell>{pagamento.observacao}</TableCell>
                            <TableCell>
                              {formatCurrency(pagamento.totalPago)}
                            </TableCell>
                            <TableCell>
                              {pagamento.urlAnexo && (
                                <Tooltip title="Visualizar Anexo">
                                  <IconButton
                                    onClick={() =>
                                      handleViewAttachment(pagamento.urlAnexo)
                                    }
                                  >
                                    <LinkIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Reimprimir PDF">
                                <IconButton onClick={() => generatePDF(pagamento)}>
                                  <PictureAsPdfIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                          {expandedRowId === pagamento.id && (
                            <TableRow>
                              <TableCell
                                colSpan={7}
                                sx={{ backgroundColor: "#f9f9f9", padding: "16px" }} // Reduzindo padding
                              >
                                <Collapse
                                  in={expandedRowId === pagamento.id}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Typography
                                      variant="subtitle1"
                                      gutterBottom
                                      component="div"
                                      sx={{ color: theme.palette.secondary.main }}
                                    >
                                      Detalhes dos Produtos
                                    </Typography>
                                    <Paper
                                      elevation={2}
                                      sx={{
                                        padding: "8px", // Diminuindo padding
                                        backgroundColor: "#fafafa",
                                        borderRadius: "8px",
                                        animation: "fadeIn 0.5s"
                                      }}
                                    >
                                      <Table size="small" aria-label="purchases">
                                        <TableHead>
                                          <TableRow
                                            sx={{
                                              backgroundColor: theme.palette.primary.light
                                            }}
                                          >
                                            {[
                                              "Produto",
                                              "Preço Unitário",
                                              "Quantidade",
                                              "Desconto",
                                              "Preço Final",
                                              "Repasse ao Fornecedor"
                                            ].map(text => (
                                              <TableCell
                                                key={text}
                                                sx={{
                                                  color: "#fff",
                                                  fontWeight: "bold"
                                                }}
                                              >
                                                {text}
                                              </TableCell>
                                            ))}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {Array.isArray(pagamento.vendasPagas) ? (
                                            pagamento.vendasPagas.flatMap(venda =>
                                              Array.isArray(venda.produtos) ? (
                                                venda.produtos.map((produto, index) => (
                                                  <TableRow key={index}>
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                    >
                                                      {produto.descricao}
                                                    </TableCell>
                                                    <TableCell>
                                                      {formatCurrency(
                                                        parseFloat(produto.valorVenda)
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      {produto.quantidade}
                                                    </TableCell>
                                                    <TableCell>
                                                      {produto.descontoPercentual > 0
                                                        ? `${produto.descontoPercentual}%`
                                                        : produto.descontoMonetario > 0
                                                        ? formatCurrency(
                                                            parseFloat(produto.descontoMonetario)
                                                          )
                                                        : "0"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {formatCurrency(
                                                        parseFloat(produto.precoFinal)
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      {formatCurrency(
                                                        parseFloat(produto.repasseFornecedor)
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                ))
                                              ) : (
                                                <TableRow>
                                                  <TableCell colSpan={6} align="center">
                                                    Nenhum produto encontrado.
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )
                                          ) : (
                                            <TableRow>
                                              <TableCell colSpan={6} align="center">
                                                Nenhuma venda encontrada.
                                              </TableCell>
                                            </TableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Paper>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          Nenhum pagamento encontrado.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </Card>
          </Container>
        </Box>
   

  );
}

export default DetalhesPagamentoFornecedor;
