import React from "react";
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles'; // Importe o ThemeProvider
import CssBaseline from '@mui/material/CssBaseline'; // Para aplicar o reset global de CSS
import App from "./App";
import theme from './theme'; // Importando o tema

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
