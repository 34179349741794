import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Corrigido aqui
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { TextField, Button, Container, Typography } from "@mui/material";

const AprovacaoOrcamento = () => {
  const { id } = useParams();
  const [orcamento, setOrcamento] = useState(null);
  const [motivoRejeicao, setMotivoRejeicao] = useState("");
  const navigate = useNavigate(); // Corrigido aqui

  useEffect(() => {
    const fetchOrcamento = async () => {
      const orcamentoRef = doc(getFirestore(), "EMPRESAS", "seu-empresa-id", "Lista de Orçamentos", id);
      const orcamentoSnap = await getDoc(orcamentoRef);
      if (orcamentoSnap.exists()) {
        setOrcamento(orcamentoSnap.data());
      } else {
        console.error("Orçamento não encontrado");
      }
    };

    fetchOrcamento();
  }, [id]);

  const handleAprovar = async () => {
    try {
      const orcamentoRef = doc(getFirestore(), "EMPRESAS", "seu-empresa-id", "Lista de Orçamentos", id);
      await updateDoc(orcamentoRef, { status: "Aprovado" });
      alert("Orçamento aprovado com sucesso!");
      navigate("/orcamentos"); // Corrigido aqui
    } catch (error) {
      console.error("Erro ao aprovar orçamento:", error);
    }
  };

  const handleReprovar = async () => {
    try {
      const orcamentoRef = doc(getFirestore(), "EMPRESAS", "seu-empresa-id", "Lista de Orçamentos", id);
      await updateDoc(orcamentoRef, { status: "Reprovado", motivoRejeicao });
      alert("Orçamento reprovado!");
      navigate("/orcamentos"); // Corrigido aqui
    } catch (error) {
      console.error("Erro ao reprovar orçamento:", error);
    }
  };

  if (!orcamento) return <div>Carregando...</div>;

  return (
    <Container>
      <Typography variant="h4">Aprovação de Orçamento</Typography>
      <Typography variant="h6">Fornecedor: {orcamento.fornecedor}</Typography>
      <Typography variant="h6">Status Atual: {orcamento.status}</Typography>

      <Button variant="contained" color="primary" onClick={handleAprovar} sx={{ marginRight: 2 }}>
        Aprovar
      </Button>
      <Button variant="contained" color="secondary" onClick={handleReprovar}>
        Reprovar
      </Button>

      <TextField
        label="Motivo da Rejeição (opcional)"
        value={motivoRejeicao}
        onChange={(e) => setMotivoRejeicao(e.target.value)}
        fullWidth
        margin="normal"
        sx={{ marginTop: 2 }}
      />
    </Container>
  );
};

export default AprovacaoOrcamento;
