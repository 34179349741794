import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import {
  collection,
  query,
  getDocs,
  getDoc,
  updateDoc,
  getFirestore,
  deleteDoc,
  doc,
  writeBatch
} from "firebase/firestore";
import {
  AppBar,
  Toolbar,
  Container,
  TextField,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  FormControl,
  Typography,
  Select,
  InputLabel,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Divider,
  Tooltip,
  Box
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import DownloadIcon from "@mui/icons-material/GetApp";
import ApprovalIcon from "@mui/icons-material/Check";
import { green, grey, yellow, red, blue } from "@mui/material/colors";
import jsPDF from "jspdf";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';



// Define the theme here
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2"
    },
    secondary: {
      main: "#dc004e"
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#f0f4f7",
          color: "#000"
        }
      }
    }
  }
});

const ListaDeOrcamentos = () => {
  const currentTheme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [orcamentos, setOrcamentos] = useState([]);
  const [busca, setBusca] = useState("");
  const [filtro, setFiltro] = useState("id");
  const [expanded, setExpanded] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrcamento, setSelectedOrcamento] = useState(null);
  const [editingItems, setEditingItems] = useState({});
  const [newItem, setNewItem] = useState({
    descricao: "",
    quantidade: "",
    valorVenda: ""
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteItemType, setDeleteItemType] = useState(null);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [marcas, setMarcas] = useState([]);


  const isMdUp = useMediaQuery(currentTheme.breakpoints.up("md"));
  
  useEffect(() => {
    const fetchCategoriasEMarcas = async () => {
      const empresaDocRef = doc(db, "EMPRESAS", empresaId);
      const empresaDoc = await getDoc(empresaDocRef);
      if (empresaDoc.exists()) {
        setCategorias(empresaDoc.data().Categorias || []);
        setMarcas(empresaDoc.data().Marcas || []);
      } else {
        console.error("Documento da empresa não encontrado.");
      }
    };

    if (empresaId) {
      fetchCategoriasEMarcas();
    }
  }, [empresaId]);

  useEffect(() => {
    const fetchOrcamentos = async () => {
      const q = query(
        collection(db, "EMPRESAS", empresaId, "Lista de Orçamentos")
      );
      const querySnapshot = await getDocs(q);
      const orcamentosData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOrcamentos(orcamentosData);
    };

    fetchOrcamentos();
  }, [empresaId]);

  const handleBuscaChange = event => {
    setBusca(event.target.value);
  };

  const handleToggle = id => {
    setExpanded(expanded === id ? null : id);
  };
  const handleOpenAddItemModal = () => {
    setAddItemModalOpen(true);
  };

  const handleCloseAddItemModal = () => {
    setAddItemModalOpen(false);
  };

  const handleOpenModal = (orcamento) => {
    if (!orcamento) {
      console.error("Nenhum orçamento selecionado.");
      return;
    }
    setSelectedOrcamento(orcamento); // Certificar-se de que o orçamento está definido
    setModalOpen(true); // Abrir o modal de edição
  };



  const formatCurrency = value => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOrcamento(null);
    setEditingItems({});
    setNewItem({ descricao: "", quantidade: "", valorVenda: "" });
  };

  const handleStatusChange = async (id, status) => {
    const orcamentoRef = doc(
      db,
      "EMPRESAS",
      empresaId,
      "Lista de Orçamentos",
      id
    );
    await updateDoc(orcamentoRef, { status });
    setOrcamentos(prevOrcamentos =>
      prevOrcamentos.map(orcamento =>
        orcamento.id === id ? { ...orcamento, status } : orcamento
      )
    );
  };

  const confirmDeleteItem = (id, type) => {
    setDeleteItemId(id);
    setDeleteItemType(type);
    setConfirmDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    setDeleteItemId(null);
    setDeleteItemType(null);
  };

  const handleConfirmDelete = async () => {
    if (deleteItemType === "orcamento") {
      await deleteOrcamento(deleteItemId);
    } else if (deleteItemType === "peca") {
      await deletePeca(selectedOrcamento.id, deleteItemId);
    }
    setConfirmDialogOpen(false);
    setDeleteItemId(null);
    setDeleteItemType(null);
  };

  const deleteOrcamento = async id => {
    const orcamentoRef = doc(
      db,
      "EMPRESAS",
      empresaId,
      "Lista de Orçamentos",
      id
    );
    await deleteDoc(orcamentoRef);
    setOrcamentos(prevOrcamentos =>
      prevOrcamentos.filter(orcamento => orcamento.id !== id)
    );
  };

  const deletePeca = async (orcamentoId, pecaId) => {
    const orcamentoRef = doc(
      db,
      "EMPRESAS",
      empresaId,
      "Lista de Orçamentos",
      orcamentoId
    );
    const orcamentoDoc = await getDoc(orcamentoRef);
    const orcamentoData = orcamentoDoc.data();
    const updatedPecas = orcamentoData.pecas.filter(peca => peca.id !== pecaId);
    await updateDoc(orcamentoRef, { pecas: updatedPecas });
    setOrcamentos(prevOrcamentos =>
      prevOrcamentos.map(orcamento =>
        orcamento.id === orcamentoId
          ? { ...orcamento, pecas: updatedPecas }
          : orcamento
      )
    );
  };

  const handleAddNewItem = async () => {
    if (!selectedOrcamento) {
      console.error("Nenhum orçamento selecionado.");
      return;
    }

    const dataEntrada = new Date().toISOString().split('T')[0]; // Data atual formatada
    const orcamentoId = selectedOrcamento.id; // ID do orçamento selecionado

    const newPeca = {
      id: Date.now().toString(),
      descricao: newItem.descricao,
      categoria: newItem.categoria,
      marca: newItem.marca,
      tamanho: newItem.tamanho,
      quantidade: newItem.quantidade,
      valorCompra: parseFloat(newItem.valorCompra),
      valorVenda: parseFloat(newItem.valorVenda),
      imageUrl: newItem.imageUrl || "",
      fornecedor: selectedOrcamento.fornecedor, // Usar o fornecedor do orçamento selecionado
      orcamentoId: orcamentoId,
      status: "Pendente",
      dataEntrada: dataEntrada,
    };

    const updatedPecas = [...(selectedOrcamento.pecas || []), newPeca];

    // Atualizar o Firestore com o novo item no orçamento selecionado
    await updateDoc(
      doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", orcamentoId),
      { pecas: updatedPecas }
    );

    // Atualizar a lista de orçamentos no estado
    setOrcamentos((prev) =>
      prev.map((orcamento) =>
        orcamento.id === orcamentoId
          ? { ...orcamento, pecas: updatedPecas }
          : orcamento
      )
    );

    setNewItem({ descricao: "", categoria: "", marca: "", tamanho: "", quantidade: "", valorCompra: 0, valorVenda: "", imageUrl: "" });
    setAddItemModalOpen(false); // Fechar o modal de inclusão de itens
  };





  const handleEditItem = (orcamentoId, pecaId) => {
    const orcamento = orcamentos.find(o => o.id === orcamentoId);
    const peca = orcamento.pecas.find(p => p.id === pecaId);
    setEditingItems(prevEditingItems => ({
      ...prevEditingItems,
      [pecaId]: { ...peca, orcamentoId }
    }));
  };

  const handleSaveItem = async (orcamentoId, updatedItem, itemId) => {
    const orcamentoRef = doc(
      db,
      "EMPRESAS",
      empresaId,
      "Lista de Orçamentos",
      orcamentoId
    );
    const orcamentoDoc = await getDoc(orcamentoRef);
    const orcamentoData = orcamentoDoc.data();
    const updatedPecas = orcamentoData.pecas.map(peca =>
      peca.id === itemId ? updatedItem : peca
    );
    await updateDoc(orcamentoRef, { pecas: updatedPecas });
    setOrcamentos(prevOrcamentos =>
      prevOrcamentos.map(orcamento =>
        orcamento.id === orcamentoId
          ? { ...orcamento, pecas: updatedPecas }
          : orcamento
      )
    );
    setEditingItems(prevEditingItems => {
      const { [itemId]: _, ...rest } = prevEditingItems;
      return rest;
    });
  };

  const enviarEmailComPDF = async orcamento => {
    setAlertMessage("Enviando email para o fornecedor...");
    setAlertModalOpen(true);

    const fornecedorRef = doc(
      getFirestore(),
      "EMPRESAS",
      empresaId,
      "Cadastro de fornecedores",
      orcamento.fornecedor
    );
    const fornecedorDoc = await getDoc(fornecedorRef);

    if (!fornecedorDoc.exists()) {
      alert("Erro: Fornecedor não encontrado.");
      return;
    }

    const fornecedorData = fornecedorDoc.data();
    const fornecedorEmail = fornecedorData.email;

    if (!fornecedorEmail) {
      alert("Erro: Fornecedor não possui email cadastrado.");
      return;
    }

    const attachmentBase64 = await pdfBlobToBase64(orcamento);
    const mailData = {
      to: fornecedorEmail,
      subject: `Orçamento ${empresaId} para o fornecedor ${orcamento.fornecedor}`,
      text: "Segue em anexo o orçamento solicitado.",
      attachment: attachmentBase64,
      filename: `Orcamento_${empresaId}_${orcamento.fornecedor}.pdf`
    };

    try {
      const response = await fetch(
        "https://us-central1-dona-cora.cloudfunctions.net/sendEmailWithPDF",
        {
          method: "POST",
          body: JSON.stringify(mailData),
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(responseText);
      }

      setAlertMessage("Email enviado com sucesso.");
      setTimeout(() => {
        setAlertModalOpen(false);
      }, 2000);
    } catch (error) {
      console.error("Erro ao enviar email:", error);
      alert("Erro ao enviar email: " + error.message);
      setAlertModalOpen(false);
    }
  };

  const pdfBlobToBase64 = orcamento => {
    const doc = new jsPDF();
    doc.text(`Orçamento ${orcamento.id}`, 10, 10);
    doc.text(`Fornecedor: ${orcamento.fornecedor}`, 10, 20);
    orcamento.pecas.forEach((peca, index) => {
      doc.text(
        `${index + 1}. ${peca.descricao} - Quantidade: ${
          peca.quantidade
        } - Preço: R$${peca.valorVenda}`,
        10,
        30 + index * 10
      );
    });
    const pdfBlob = doc.output("blob");
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(pdfBlob);
    });
  };

  const handleDownloadPDF = orcamento => {
    const doc = new jsPDF();
    doc.text(`Orçamento ${orcamento.id}`, 10, 10);
    doc.text(`Fornecedor: ${orcamento.fornecedor}`, 10, 20);
    orcamento.pecas.forEach((peca, index) => {
      doc.text(
        `${index + 1}. ${peca.descricao} - Quantidade: ${
          peca.quantidade
        } - Preço: R$${peca.valorVenda}`,
        10,
        30 + index * 10
      );
    });
    doc.save(`Orcamento_${orcamento.id}.pdf`);
  };

  const handleApprove = async orcamento => {
    if (orcamento.status === "aprovado") {
      alert(
        "Este orçamento já está aprovado e não pode ser aprovado novamente."
      );
      return;
    }

    setAlertMessage("Aprovando orçamento...");
    setAlertModalOpen(true);

    const estoqueCollectionRef = collection(
      db,
      "EMPRESAS",
      empresaId,
      "Cadastro de Peças",
      "dados",
      "Estoque Ativo"
    );

    const orcamentoRef = doc(
      db,
      "EMPRESAS",
      empresaId,
      "Lista de Orçamentos",
      orcamento.id
    );

    const saveBatch = async batchPecas => {
      const batch = writeBatch(db);
      for (const peca of batchPecas) {
        const codigoPeca = Date.now()
          .toString()
          .slice(-8);
        const pecaRef = doc(estoqueCollectionRef, codigoPeca);
        batch.set(pecaRef, {
          categoria: peca.categoria || "Desconhecido",
          codigoPeca: codigoPeca,
          dataEntrada: new Date().toISOString().split("T")[0],
          descricao: peca.descricao,
          fornecedor: orcamento.fornecedor,
          imageUrl: peca.imageUrl || "/static/imagem_padrao.png",
          marca: peca.marca || "Desconhecido",
          quantidade: peca.quantidade,
          tamanho: peca.tamanho || "Único",
          valorCompra: peca.valorCompra,
          valorVenda: peca.valorVenda,
          orcamentoId: orcamento.id
        });
      }
      await batch.commit();
    };

    try {
      for (const peca of orcamento.pecas) {
        await saveBatch([peca]);
      }

      await updateDoc(orcamentoRef, { status: "aprovado" });

      setOrcamentos(prevOrcamentos =>
        prevOrcamentos.map(o =>
          o.id === orcamento.id ? { ...o, status: "aprovado" } : o
        )
      );
      setAlertMessage("Orçamento aprovado e itens movidos para o estoque.");
      setTimeout(() => {
        setAlertModalOpen(false);
      }, 2000);
    } catch (error) {
      console.error("Erro ao aprovar orçamento:", error);
      alert("Erro ao aprovar orçamento: " + error.message);
      setAlertModalOpen(false);
    }
  };

  const filtrarOrcamentos = () => {
    return orcamentos.filter(orcamento => {
      if (filtro === "id") {
        return orcamento.id.includes(busca);
      } else if (filtro === "fornecedor") {
        return orcamento.fornecedor.includes(busca);
      }
      return true;
    });
  };

  const getStatusIcon = status => {
    switch (status) {
      case "aprovado":
        return <CheckCircleOutlineIcon style={{ color: green[500] }} />;
      case "pendente":
        return <HourglassEmptyIcon style={{ color: yellow[700] }} />;
      default:
        return <HourglassEmptyIcon style={{ color: grey[700] }} />;
    }
  };

  const renderOrcamentoActions = orcamento => {
    const isApproved = orcamento.status === "aprovado";
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Expandir">
          <IconButton onClick={() => handleToggle(orcamento.id)}>
            {expanded === orcamento.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Editar">
          <IconButton onClick={() => handleOpenModal(orcamento)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Excluir">
          <IconButton onClick={() => confirmDeleteItem(orcamento.id, "orcamento")}>
            <DeleteIcon sx={{ color: red[500] }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Enviar Email">
          <IconButton onClick={() => enviarEmailComPDF(orcamento)}>
            <SendIcon sx={{ color: blue[500] }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Baixar PDF">
          <IconButton onClick={() => handleDownloadPDF(orcamento)}>
            <DownloadIcon sx={{ color: grey[700] }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Aprovar">
          <span>
            <IconButton
              onClick={() => handleApprove(orcamento)}
              disabled={isApproved}
            >
              <ApprovalIcon sx={{ color: isApproved ? grey[500] : green[500] }} />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    );
  };

  return (
      <Container
        maxWidth="xl"
        style={{
          marginTop: "20px",
          paddingLeft: isMdUp ? "230px" : "16px",
          paddingRight: "16px",
          maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
        }}
      >
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Lista de Orçamentos
            </Typography>
            <TextField
              label="Buscar Orçamento"
              variant="outlined"
              value={busca}
              onChange={handleBuscaChange}
              size="small"
              sx={{ marginRight: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setFiltro("id")}
              sx={{ marginRight: 2 }}
            >
              Buscar por ID
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setFiltro("fornecedor")}
            >
              Buscar por Fornecedor
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography
                variant="h6"
                sx={{ textAlign: "left", marginTop: 2, marginBottom: 2 }}
              >
                Lista de Orçamentos por Aprovação
              </Typography>
              <List>
                {filtrarOrcamentos().map((orcamento, index) => (
                  <React.Fragment key={index}>
                    <Paper elevation={3} sx={{ marginBottom: 2, padding: 2 }}>
                      <ListItem>
                        <ListItemText
                          primary={`Orçamento ID: ${orcamento.id}`}
                          secondary={`Fornecedor: ${orcamento.fornecedor}`}
                          primaryTypographyProps={{ fontWeight: "bold" }}
                          secondaryTypographyProps={{ color: "textSecondary" }}
                        />
                        <ListItemSecondaryAction>
                          {getStatusIcon(orcamento.status)}
                          {renderOrcamentoActions(orcamento)}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Collapse
                        in={expanded === orcamento.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {orcamento.pecas &&
                            orcamento.pecas.map((peca, pecaIndex) => (
                              <React.Fragment key={peca.id}>
                                <ListItem sx={{ paddingLeft: 4 }}>
                                  {editingItems[peca.id] ? (
                                    <Box sx={{ width: "100%" }}>
                                      <TextField
                                        label="Descrição"
                                        value={editingItems[peca.id].descricao}
                                        onChange={e =>
                                          setEditingItems(prevEditingItems => ({
                                            ...prevEditingItems,
                                            [peca.id]: {
                                              ...prevEditingItems[peca.id],
                                              descricao: e.target.value
                                            }
                                          }))
                                        }
                                        fullWidth
                                        margin="normal"
                                      />
                                      <TextField
                                        label="Quantidade"
                                        value={editingItems[peca.id].quantidade}
                                        onChange={e =>
                                          setEditingItems(prevEditingItems => ({
                                            ...prevEditingItems,
                                            [peca.id]: {
                                              ...prevEditingItems[peca.id],
                                              quantidade: e.target.value
                                            }
                                          }))
                                        }
                                        fullWidth
                                        margin="normal"
                                      />
                                      <TextField
                                        label="Preço"
                                        value={editingItems[peca.id].valorVenda}
                                        onChange={e =>
                                          setEditingItems(prevEditingItems => ({
                                            ...prevEditingItems,
                                            [peca.id]: {
                                              ...prevEditingItems[peca.id],
                                              valorVenda: e.target.value
                                            }
                                          }))
                                        }
                                        fullWidth
                                        margin="normal"
                                      />
                                      <Button
                                        onClick={() =>
                                          handleSaveItem(
                                            orcamento.id,
                                            editingItems[peca.id],
                                            peca.id
                                          )
                                        }
                                        color="primary"
                                      >
                                        Salvar
                                      </Button>
                                    </Box>
                                  ) : (
                                    <>
                                      <ListItemText
                                        primary={peca.descricao}
                                        secondary={`Quantidade: ${peca.quantidade} - Preço: ${formatCurrency(peca.valorVenda)}`}
                                        primaryTypographyProps={{
                                          fontWeight: "bold"
                                        }}
                                        secondaryTypographyProps={{
                                          color: "textSecondary"
                                        }}
                                      />
                                      <Tooltip title="Editar Item">
                                        <IconButton
                                          onClick={() =>
                                            handleEditItem(
                                              orcamento.id,
                                              peca.id
                                            )
                                          }
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Excluir Item">
                                        <IconButton
                                          onClick={() =>
                                            confirmDeleteItem(peca.id, "peca")
                                          }
                                        >
                                          <DeleteIcon
                                            sx={{ color: red[500] }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </ListItem>
                                {pecaIndex < orcamento.pecas.length - 1 && (
                                  <Divider variant="inset" component="li" />
                                )}
                              </React.Fragment>
                            ))}
                        </List>
                        <Divider />
                        
                      </Collapse>
                    </Paper>
                  </React.Fragment>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
        {selectedOrcamento && (
          <Dialog
            open={modalOpen}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              Orçamento ID: {selectedOrcamento.id}
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Fornecedor: {selectedOrcamento.fornecedor}
              </Typography>
              <Typography gutterBottom>
                Status: {selectedOrcamento.status}
              </Typography>
              {selectedOrcamento.pecas &&
                selectedOrcamento.pecas.map(peca => (
                  <Box key={peca.id} sx={{ marginBottom: 2 }}>
                    {editingItems[peca.id] ? (
                    <Box sx={{ width: "100%" }}>
                      {/* Campo para Descrição */}
                      <TextField
                        label="Descrição"
                        value={editingItems[peca.id].descricao}
                        onChange={(e) =>
                          setEditingItems((prev) => ({
                            ...prev,
                            [peca.id]: { ...prev[peca.id], descricao: e.target.value },
                          }))
                        }
                        fullWidth
                        margin="normal"
                      />

                      {/* Campo para Categoria */}
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Categoria</InputLabel>
                        <Select
                          value={editingItems[peca.id].categoria}
                          onChange={(e) =>
                            setEditingItems((prev) => ({
                              ...prev,
                              [peca.id]: { ...prev[peca.id], categoria: e.target.value },
                            }))
                          }
                          label="Categoria"
                        >
                          {categorias.map((cat, index) => (
                            <MenuItem key={index} value={cat}>
                              {cat}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Campo para Marca */}
                      <Autocomplete
                        freeSolo
                        options={marcas.map((marca) => marca)}
                        value={editingItems[peca.id].marca}
                        onChange={(event, newValue) =>
                          setEditingItems((prev) => ({
                            ...prev,
                            [peca.id]: { ...prev[peca.id], marca: newValue },
                          }))
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Marca" margin="normal" fullWidth />
                        )}
                      />

                      {/* Campo para Quantidade */}
                      <TextField
                        label="Quantidade"
                        type="number"
                        value={editingItems[peca.id].quantidade}
                        onChange={(e) =>
                          setEditingItems((prev) => ({
                            ...prev,
                            [peca.id]: { ...prev[peca.id], quantidade: e.target.value },
                          }))
                        }
                        fullWidth
                        margin="normal"
                      />

                      {/* Campo para Valor de Venda */}
                      <TextField
                        label="Valor de Venda"
                        type="number"
                        value={editingItems[peca.id].valorVenda}
                        onChange={(e) =>
                          setEditingItems((prev) => ({
                            ...prev,
                            [peca.id]: { ...prev[peca.id], valorVenda: e.target.value },
                          }))
                        }
                        fullWidth
                        margin="normal"
                      />

                      <Button
                        onClick={() => handleSaveItem(selectedOrcamento.id, editingItems[peca.id], peca.id)}
                        color="primary"
                      >
                        Salvar
                      </Button>
                    </Box>

                    ) : (
                      <Box>
                        <Typography gutterBottom>
                          Descrição: {peca.descricao}
                        </Typography>
                        <Typography gutterBottom>
                          Quantidade: {peca.quantidade}
                        </Typography>
                        <Typography gutterBottom>
                          Preço: {formatCurrency(peca.valorVenda)}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            handleEditItem(selectedOrcamento.id, peca.id)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Fechar
              </Button>
              <Button onClick={handleOpenAddItemModal} color="secondary" variant="contained">
                Incluir Novo Item
              </Button>
            </DialogActions>

          </Dialog>
        )}
        <Dialog open={addItemModalOpen} onClose={handleCloseAddItemModal} fullWidth maxWidth="sm">
          <DialogTitle>Adicionar Novo Item</DialogTitle>
          <DialogContent dividers>
            {/* Campo para Descrição */}
            <TextField
              label="Descrição"
              value={newItem.descricao}
              onChange={(e) => setNewItem({ ...newItem, descricao: e.target.value })}
              fullWidth
              margin="normal"
            />

            {/* Campo de seleção de Categoria */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Categoria</InputLabel>
              <Select
                value={newItem.categoria}
                onChange={(e) => setNewItem({ ...newItem, categoria: e.target.value })}
                label="Categoria"
              >
                {categorias.map((cat, index) => (
                  <MenuItem key={index} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Campo de seleção de Marca */}
            <Autocomplete
              freeSolo
              options={marcas.map((marca) => marca)}
              value={newItem.marca}
              onChange={(event, newValue) => setNewItem({ ...newItem, marca: newValue })}
              renderInput={(params) => (
                <TextField {...params} label="Marca" margin="normal" fullWidth />
              )}
            />

            {/* Campo para Tamanho */}
            <TextField
              label="Tamanho"
              value={newItem.tamanho}
              onChange={(e) => setNewItem({ ...newItem, tamanho: e.target.value })}
              fullWidth
              margin="normal"
            />

            {/* Campo para Quantidade */}
            <TextField
              label="Quantidade"
              type="number"
              value={newItem.quantidade}
              onChange={(e) => setNewItem({ ...newItem, quantidade: e.target.value })}
              fullWidth
              margin="normal"
            />

           

            {/* Campo para Valor de Venda */}
            <TextField
              label="Valor de Venda"
              type="number"
              value={newItem.valorVenda}
              onChange={(e) => setNewItem({ ...newItem, valorVenda: e.target.value })}
              fullWidth
              margin="normal"
            />

            
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseAddItemModal} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleAddNewItem} color="secondary" variant="contained">
              Adicionar Item
            </Button>
          </DialogActions>
        </Dialog>



        <Dialog
          open={confirmDialogOpen}
          onClose={handleCancelDelete}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Tem certeza de que deseja excluir este item?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleConfirmDelete} color="secondary">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={alertModalOpen}
          onClose={() => setAlertModalOpen(false)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Aviso</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>{alertMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setAlertModalOpen(false)}
              color="primary"
              autoFocus
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </Container>
  );
};

export default ListaDeOrcamentos;
