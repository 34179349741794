import React, { Component } from "react";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";

class ImpressaoEtiqueta extends Component {
  componentDidMount() {
    this.gerarEtiqueta();
  }

  gerarEtiqueta = () => {
    const {
      codigo,
      descricao,
      tamanho,
      marca,
      categoria,
      valorVenda
    } = this.props;
    console.log(`Gerando etiqueta para o código: ${codigo}`);
    let canvas = document.createElement("canvas");
    JsBarcode(canvas, codigo, { format: "CODE39", width: 2, height: 40 });
    let codigoBarras = canvas.toDataURL("image/png");

    QRCode.toDataURL(codigo)
      .then(url => {
        let qrCode = url;
        let conteudoEtiqueta = `
        <div style="font-family: Arial, sans-serif; text-align: center;">
          <p><strong>Código da Peça:</strong> ${codigo}</p>
          <p><strong>Descrição:</strong> ${descricao}</p>
          <p><strong>Tamanho:</strong> ${tamanho}</p>
          <p><strong>Marca:</strong> ${marca}</p>
          <p><strong>Categoria:</strong> ${categoria}</p>
          <p><strong>Valor de Venda:</strong> R$ ${valorVenda}</p>
          <img src="${codigoBarras}" alt="Código de Barras"/>
          <img src="${qrCode}" alt="QR Code"/>
        </div>
      `;

        let janelaImpressao = window.open("", "_blank", "width=400,height=600");
        if (janelaImpressao) {
          janelaImpressao.document.write(conteudoEtiqueta);
          janelaImpressao.document.close();
          console.log(`Etiqueta gerada para o código: ${codigo}`);
          setTimeout(() => {
            janelaImpressao.focus();
            janelaImpressao.print();
            janelaImpressao.close();
            console.log(`Etiqueta impressa para o código: ${codigo}`);
          }, 500);
        } else {
          console.error(
            "A janela de impressão não foi criada. Popup bloqueado?"
          );
        }
      })
      .catch(error => {
        console.error("Erro ao gerar QR Code:", error);
      });
  };

  render() {
    return null; // Este componente não renderiza nada visualmente
  }
}

export default ImpressaoEtiqueta;
