import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  TextField,
  Container,
  TableRow,
  Card,
  useMediaQuery,
  Typography,
  CardContent,
  Grid,
  Paper,
  Box,
  Button,
  Tooltip,
  Collapse,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";
import { createTheme, useTheme } from "@mui/material/styles";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  AttachMoney as AttachMoneyIcon,
  TrendingUp as TrendingUpIcon,
  MonetizationOn as MonetizationOnIcon,
  AccountBalance as AccountBalanceIcon,
  Refresh as RefreshIcon,
  BarChart as BarChartIcon,
  SaveAlt as SaveAltIcon,
  Info as InfoIcon
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { jsPDF } from "jspdf";
import PrintIcon from "@mui/icons-material/Print";
import { Line } from "react-chartjs-2";
import "jspdf-autotable";

const formatCurrency = value => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);
};

function CaixaEmpresa() {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [vendas, setVendas] = useState([]);
  const [custosEfetivos, setCustosEfetivos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anoSelecionado, setAnoSelecionado] = useState(
    new Date().getFullYear()
  );
  const [mesSelecionado, setMesSelecionado] = useState("");
  const [modalidadeSelecionada, setModalidadeSelecionada] = useState("");
  const [expandedRowId, setExpandedRowId] = useState(null);

  const handleToggleRow = id => {
    setExpandedRowId(expandedRowId === id ? null : id); // Toggle
  };

  const carregarVendas = useCallback(async () => {
    setLoading(true);
    try {
      const vendasData = [];
      const promises = [];

      for (let mes = 1; mes <= 12; mes++) {
        if (mesSelecionado && mes !== mesSelecionado) continue;
        for (let dia = 1; dia <= 31; dia++) {
          const vendasRef = collection(
            db,
            `EMPRESAS/${empresaId}/Vendas/${anoSelecionado}/${mes}/${dia}/Vendas`
          );
          promises.push(getDocs(vendasRef));
        }
      }

      const results = await Promise.all(promises);

      results.forEach(querySnapshot => {
        querySnapshot.forEach(doc => {
          const vendaData = { id: doc.id, ...doc.data() };
          if (
            !modalidadeSelecionada ||
            vendaData.metodoPagamento[modalidadeSelecionada]
          ) {
            vendasData.push(vendaData);
          }
        });
      });

      setVendas(vendasData);
    } catch (error) {
      console.error("Erro ao buscar vendas:", error);
    }
    setLoading(false);
  }, [empresaId, anoSelecionado, mesSelecionado, modalidadeSelecionada]);

  const carregarCustosEfetivos = useCallback(async () => {
    try {
      const empresaRef = doc(db, "EMPRESAS", empresaId);
      const empresaDoc = await getDoc(empresaRef);
      if (empresaDoc.exists()) {
        const data = empresaDoc.data();
        setCustosEfetivos(data.CustosEfetivos || []);
      }
    } catch (error) {
      console.error("Erro ao buscar custos efetivos:", error);
    }
  }, [empresaId]);

  useEffect(() => {
    carregarVendas();
    carregarCustosEfetivos();
  }, [carregarVendas, carregarCustosEfetivos]);

  const calcularValoresPorModalidade = useMemo(() => {
    const valores = {
      dinheiro: 0,
      pix: 0,
      cartao: 0
    };

    vendas.forEach(venda => {
      if (venda.metodoPagamento.dinheiro) {
        valores.dinheiro += venda.totalCaixaEmpresa;
      } else if (venda.metodoPagamento.pix) {
        valores.pix += venda.totalCaixaEmpresa;
      } else {
        valores.cartao += venda.totalCaixaEmpresa;
      }
    });

    return {
      dinheiro: formatCurrency(valores.dinheiro),
      pix: formatCurrency(valores.pix),
      cartao: formatCurrency(valores.cartao)
    };
  }, [vendas]);

  const calcularSubtotal = useMemo(() => {
    return vendas
      .reduce((total, venda) => {
        let totalVenda = 0;
        Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
          produtos.forEach(produto => {
            totalVenda += parseFloat(produto.precoFinal) || 0;
          });
        });
        return total + totalVenda;
      }, 0)
      .toFixed(2);
  }, [vendas]);

  const dadosGrafico = useMemo(() => {
    const meses = Array.from({ length: 12 }, (_, index) => index + 1);
    const valoresMensais = meses.map(mes => {
      const totalMes = vendas
        .filter(
          venda =>
            new Date(venda.dataVenda.seconds * 1000).getMonth() + 1 === mes
        )
        .reduce((total, venda) => {
          let totalVenda = 0;
          Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
            produtos.forEach(produto => {
              totalVenda += parseFloat(produto.precoFinal) || 0;
            });
          });
          return total + totalVenda;
        }, 0);
      return totalMes.toFixed(2);
    });

    return {
      labels: meses,
      datasets: [
        {
          label: "Evolução do Caixa",
          data: valoresMensais,
          fill: false,
          borderColor: theme.palette.primary.main,
          tension: 0.1
        }
      ]
    };
  }, [vendas, theme.palette.primary.main]);

  const calcularInvestimentoTotal = useMemo(() => {
    return vendas
      .reduce((total, venda) => {
        let totalInvestimento = 0;
        Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
          produtos.forEach(produto => {
            if (produto.valorCompra) {
              totalInvestimento += parseFloat(produto.valorCompra) || 0;
            }
          });
        });
        return total + totalInvestimento;
      }, 0)
      .toFixed(2);
  }, [vendas]);

  const calcularRepasseTotal = useMemo(() => {
    return vendas
      .reduce((total, venda) => {
        let totalRepasse = 0;
        Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
          produtos.forEach(produto => {
            if (produto.repasseFornecedor) {
              totalRepasse += parseFloat(produto.repasseFornecedor) || 0;
            }
          });
        });
        return total + totalRepasse;
      }, 0)
      .toFixed(2);
  }, [vendas]);

  const calcularTotalDescontos = useMemo(() => {
    return vendas
      .reduce((total, venda) => {
        let totalDescontos = 0;
        Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
          produtos.forEach(produto => {
            if (produto.descontoMonetario) {
              totalDescontos += parseFloat(produto.descontoMonetario) || 0;
            } else if (produto.descontoPercentual) {
              totalDescontos +=
                (parseFloat(produto.valorVenda) *
                  parseFloat(produto.descontoPercentual)) /
                  100 || 0;
            }
          });
        });
        return total + totalDescontos;
      }, 0)
      .toFixed(2);
  }, [vendas]);

  const calcularTotalCustosEfetivos = useMemo(() => {
    return custosEfetivos
      .reduce((total, custo) => {
        const valor = parseFloat(
          custo.valor.replace(/\./g, "").replace(",", ".")
        );
        return total + (isNaN(valor) ? 0 : valor);
      }, 0)
      .toFixed(2);
  }, [custosEfetivos]);

  const calcularSaldoCaixaEmpresa = useMemo(() => {
    const totalEntradas = vendas.reduce((total, venda) => {
      let totalVenda = 0;
      Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
        produtos.forEach(produto => {
          totalVenda += parseFloat(produto.precoFinal) || 0;
        });
      });
      return total + totalVenda;
    }, 0);

    const totalRepasse = vendas.reduce((total, venda) => {
      let totalRepasse = 0;
      Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
        produtos.forEach(produto => {
          if (produto.repasseFornecedor) {
            totalRepasse += parseFloat(produto.repasseFornecedor) || 0;
          }
        });
      });
      return total + totalRepasse;
    }, 0);

    return (totalEntradas - totalRepasse - calcularTotalCustosEfetivos).toFixed(
      2
    );
  }, [vendas, calcularTotalCustosEfetivos]);

  const handleAnoChange = date => {
    setAnoSelecionado(date.getFullYear());
  };

  const handleMesChange = event => {
    setMesSelecionado(event.target.value);
  };

  const handleModalidadeChange = event => {
    setModalidadeSelecionada(event.target.value);
  };

  const exportarPDF = () => {
    const doc = new jsPDF();
    doc.text("Relatório de Vendas", 14, 22);

    const vendaColumns = [
      "Data da Venda",
      "ID da Venda",
      "Método de Pagamento",
      "Valor de Venda",
      "Recebido pela Venda(descontado Pag fornecedores)"
    ];
    const vendaRows = vendas.map(venda => [
      new Date(venda.dataVenda.seconds * 1000).toLocaleDateString("pt-BR"),
      venda.idVenda,
      venda.metodoPagamento.dinheiro
        ? "Dinheiro"
        : venda.metodoPagamento.pix
        ? "Pix"
        : "Cartão",
      formatCurrency(venda.totalCaixaEmpresa)
    ]);

    doc.autoTable({
      head: [vendaColumns],
      body: vendaRows,
      startY: 30
    });

    let finalY = doc.autoTable.previous.finalY + 10;

    const custoColumns = ["Descrição do Custo", "Valor do Custo"];
    const custoRows = custosEfetivos.map(custo => [
      custo.descricao,
      formatCurrency(
        parseFloat(custo.valor.replace(/\./g, "").replace(",", "."))
      )
    ]);

    doc.text("Custos Efetivos", 14, finalY + 10);
    doc.autoTable({
      head: [custoColumns],
      body: custoRows,
      startY: finalY + 20
    });

    finalY = doc.autoTable.previous.finalY + 10;
    doc.text(
      `Total Custos Efetivos: ${formatCurrency(calcularTotalCustosEfetivos)}`,
      14,
      finalY + 10
    );

    doc.text(
      `Saldo Caixa Empresa: ${formatCurrency(calcularSaldoCaixaEmpresa)}`,
      14,
      finalY + 20
    );

    doc.save("relatorio_vendas.pdf");
  };

  

  return (
    <Box
      sx={{
        backgroundColor: "#fffff",
        minHeight: "100vh",
        display: "flex", // Adicionado para permitir o crescimento do filho
        flexDirection: "column", // Mantém a direção da coluna
      }}
    >
          <Container
            maxWidth="xl"
            style={{
              marginTop: "20px",
              paddingLeft: isMdUp ? "220px" : "16px",
              paddingRight: "16px",
              maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
            }}
          >
              <Card
                elevation={6}
                sx={{
                  padding: "0px",
                  backgroundColor: "#ffffff",
                  borderRadius: "8px", // Reduzindo borderRadius
                  marginBottom: "16px", // Reduzindo marginBottom
                  marginRight: "50px",
                  width: "100%", // Ocupar toda a largura disponível
                  flexGrow: 1, // Permite que o Card cresça para preencher o espaço
                  display: "flex", // Adicionado para alinhar com os elementos filhos
                  flexDirection: "column", // Mantém a direção da coluna
                }}
              >
                <Typography
                  variant="h5" // De h4 para h5
                  sx={{
                    marginBottom: "10px",
                    color: theme.palette.primary.dark,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Caixa da Empresa
                </Typography>
                <Typography
                  variant="subtitle1" // De h6 para subtitle1
                  sx={{
                    marginBottom: "16px", // Reduzindo marginBottom
                    color: theme.palette.primary.main,
                    textAlign: "center",
                  }}
                >
                  Filtrar Vendas por Ano, Mês e Modalidade de Pagamento
                </Typography>
                <Card
                  elevation={3}
                  sx={{
                    padding: "8px", // Reduzindo padding
                    backgroundColor: "#ffff",
                    borderRadius: "8px", // Reduzindo borderRadius
                    marginBottom: "16px", // Reduzindo marginBottom
                    flexGrow: 1, // Permite que o subcard cresça dentro do card
                  }}
                >
       
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["year"]}
                  label="Selecionar Ano"
                  value={new Date(`${anoSelecionado}-01-01`)}
                  onChange={handleAnoChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: theme.palette.primary.main
                          },
                          "&:hover fieldset": {
                            borderColor: theme.palette.primary.dark
                          }
                        }
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Mês</InputLabel>
                <Select
                  value={mesSelecionado}
                  onChange={handleMesChange}
                  label="Mês"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {[...Array(12)].map((_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Modalidade</InputLabel>
                <Select
                  value={modalidadeSelecionada}
                  onChange={handleModalidadeChange}
                  label="Modalidade"
                >
                  <MenuItem value="">Todas</MenuItem>
                  <MenuItem value="dinheiro">Dinheiro</MenuItem>
                  <MenuItem value="pix">Pix</MenuItem>
                  <MenuItem value="cartao">Cartão</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<RefreshIcon />}
                onClick={carregarVendas}
                sx={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  padding: "10px 0",
                  fontWeight: "bold",
                  textTransform: "none"
                }}
              >
                Atualizar
              </Button>
            </Grid>
          </Grid>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Card
                  elevation={3}
                  sx={{
                    padding: "16px", // Reduzindo padding
                    borderRadius: "8px", // Reduzindo borderRadius
                    backgroundColor: "#81d4fa",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    position: "relative"
                  }}
                >
                  <Tooltip title="Este é o total de entradas antes de repassar ao fornecedor">
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "#fff"
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <AttachMoneyIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      marginTop: "8px" // Reduzindo marginTop
                    }}
                  >
                    Total Entradas
                  </Typography>
                  <Typography
                    variant="h5" // De h4 para h5
                    sx={{
                      fontWeight: "bold",
                      color: "#fff"
                    }}
                  >
                    {formatCurrency(calcularSubtotal)}
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} md={3}>
                <Card
                  elevation={3}
                  sx={{
                    padding: "16px", // Reduzindo padding
                    borderRadius: "8px", // Reduzindo borderRadius
                    backgroundColor: "#81c784",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    position: "relative"
                  }}
                >
                  <Tooltip title="Este é o total investido na compra de produtos">
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "#fff"
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <TrendingUpIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      marginTop: "8px" // Reduzindo marginTop
                    }}
                  >
                    Total Investido
                  </Typography>
                  <Typography
                    variant="h5" // De h4 para h5
                    sx={{
                      fontWeight: "bold",
                      color: "#fff"
                    }}
                  >
                    {formatCurrency(calcularInvestimentoTotal)}
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} md={3}>
                <Card
                  elevation={3}
                  sx={{
                    padding: "16px", // Reduzindo padding
                    borderRadius: "8px", // Reduzindo borderRadius
                    backgroundColor: "#ffb74d",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    position: "relative"
                  }}
                >
                  <Tooltip title="Este é o total repassado ao fornecedor">
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "#fff"
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <MonetizationOnIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      marginTop: "8px" // Reduzindo marginTop
                    }}
                  >
                    Total Repasse
                  </Typography>
                  <Typography
                    variant="h5" // De h4 para h5
                    sx={{
                      fontWeight: "bold",
                      color: "#fff"
                    }}
                  >
                    {formatCurrency(calcularRepasseTotal)}
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} md={3}>
                <Card
                  elevation={3}
                  sx={{
                    padding: "16px", // Reduzindo padding
                    borderRadius: "8px", // Reduzindo borderRadius
                    backgroundColor: "#64b5f6",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    position: "relative"
                  }}
                >
                  <Tooltip title="Este é o saldo do caixa da empresa após deduzir os investimentos e custos efetivos">
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "#fff"
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <AccountBalanceIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      marginTop: "8px" // Reduzindo marginTop
                    }}
                  >
                    Saldo Caixa da Empresa
                  </Typography>
                  <Typography
                    variant="h5" // De h4 para h5
                    sx={{
                      fontWeight: "bold",
                      color: "#fff"
                    }}
                  >
                    {formatCurrency(calcularSaldoCaixaEmpresa)}
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} md={3}>
                  <Card
                    elevation={3}
                    sx={{
                      padding: "16px", // Reduzindo padding
                      borderRadius: "8px", // Reduzindo borderRadius
                      backgroundColor: "#64b5f6",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                  <Tooltip title="Este é o total de descontos aplicados">
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "#fff"
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <BarChartIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      marginTop: "10px"
                    }}
                  >
                    Total Descontos
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff"
                    }}
                  >
                    {formatCurrency(calcularTotalDescontos)}
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} md={3}>
                <Card
                  elevation={3}
                  sx={{
                    padding: "16px", // Reduzindo padding
                    borderRadius: "8px", // Reduzindo borderRadius
                    backgroundColor: "#ba68c8",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    position: "relative"
                  }}
                >
                
                  <Tooltip title="Este é o total de custos efetivos da empresa">
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "#fff"
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <MonetizationOnIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "#fffff",
                      marginTop: "10px"
                    }}
                  >
                    Total Custos Efetivos
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      color: "#fffff"
                    }}
                  >
                    {formatCurrency(calcularTotalCustosEfetivos)}
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card
                  elevation={3}
                  sx={{
                    padding: "20px",
                    borderRadius: "12px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    position: "relative",
                    flexBasis: "100%"
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                      marginTop: "10px"
                    }}
                  >
                    Evolução do Caixa
                  </Typography>
                  <Box sx={{ height: "auto" }}>
                    <Line
                      data={dadosGrafico}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Meses do Ano"
                            }
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Valor em Reais"
                            }
                          }
                        }
                      }}
                    />
                  </Box>
                </Card>
              </Grid>

              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4}>
                  <Card
                    elevation={3}
                    sx={{
                      backgroundColor: theme.palette.success.light,
                      color: theme.palette.success.contrastText,
                      padding: "20px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Total Recebido - Dinheiro
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        {calcularValoresPorModalidade.dinheiro}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card
                    elevation={3}
                    sx={{
                      backgroundColor: theme.palette.info.light,
                      color: theme.palette.info.contrastText,
                      padding: "20px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Total Recebido - Pix
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        {calcularValoresPorModalidade.pix}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card
                    elevation={3}
                    sx={{
                      backgroundColor: theme.palette.warning.light,
                      color: theme.palette.warning.contrastText,
                      padding: "20px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Total Recebido - Cartão
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        {calcularValoresPorModalidade.cartao}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PrintIcon />}
                  onClick={exportarPDF}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    padding: "10px 0",
                    fontWeight: "bold",
                    textTransform: "none",
                    marginBottom: "20px",
                    width: "100%"
                  }}
                >
                  Exportar para PDF
                </Button>
                <Card
                  elevation={3}
                  sx={{
                    padding: "20px",
                    borderRadius: "12px",
                    backgroundColor: "#ffffff",
                    boxShadow:
                      "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%"
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                      marginBottom: "20px",
                      textAlign: "center"
                    }}
                  >
                    Detalhes das Vendas
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{ backgroundColor: theme.palette.primary.main }}
                      >
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          Data da Venda
                        </TableCell>
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          ID da Venda
                        </TableCell>
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          Método de Pagamento
                        </TableCell>
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          Total recebido pela Venda
                        </TableCell>
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          Expandir
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vendas.map(venda => (
                        <React.Fragment key={venda.idVenda}>
                          <TableRow
                            hover
                            sx={{
                              "&:hover": { backgroundColor: "#e0f2f1" },
                              transition: "background-color 0.3s"
                            }}
                          >
                            <TableCell>
                              {new Date(
                                venda.dataVenda.seconds * 1000
                              ).toLocaleDateString("pt-BR")}
                            </TableCell>
                            <TableCell>{venda.idVenda}</TableCell>
                            <TableCell>
                              {venda.metodoPagamento.dinheiro
                                ? "Dinheiro"
                                : venda.metodoPagamento.pix
                                ? "Pix"
                                : "Cartão"}
                            </TableCell>
                            <TableCell>
                              {formatCurrency(venda.totalCaixaEmpresa)}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Expandir/Colapsar detalhes">
                                <IconButton
                                  onClick={() => handleToggleRow(venda.idVenda)}
                                >
                                  {expandedRowId === venda.idVenda ? (
                                    <ExpandLessIcon />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                          {expandedRowId === venda.idVenda && (
                            <TableRow>
                              <TableCell
                                colSpan={5}
                                sx={{
                                  backgroundColor: "#f9f9f9",
                                  padding: "20px"
                                }}
                              >
                                <Collapse
                                  in={expandedRowId === venda.idVenda}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                      sx={{
                                        color: theme.palette.secondary.main
                                      }}
                                    >
                                      Detalhes dos Produtos
                                    </Typography>
                                    <Paper
                                      elevation={2}
                                      sx={{
                                        padding: "10px",
                                        backgroundColor: "#fafafa",
                                        borderRadius: "8px",
                                        animation: "fadeIn 0.5s"
                                      }}
                                    >
                                      <Table
                                        size="small"
                                        aria-label="purchases"
                                      >
                                        <TableHead>
                                          <TableRow
                                            sx={{
                                              backgroundColor:
                                                theme.palette.primary.light
                                            }}
                                          >
                                            {[
                                              "Produto",
                                              "Preço Unitário",
                                              "Quantidade",
                                              "Desconto",
                                              "Preço Final de Venda",
                                              "Repasse ao Fornecedor"
                                            ].map(text => (
                                              <TableCell
                                                key={text}
                                                sx={{
                                                  color: "#fff",
                                                  fontWeight: "bold"
                                                }}
                                              >
                                                {text}
                                              </TableCell>
                                            ))}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {Object.entries(
                                            venda.produtosPorFornecedor
                                          ).flatMap(([fornecedor, produtos]) =>
                                            produtos.map((produto, index) => (
                                              <TableRow key={index}>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {produto.descricao}
                                                </TableCell>
                                                <TableCell>
                                                  {formatCurrency(
                                                    parseFloat(
                                                      produto.valorVenda
                                                    )
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  {produto.quantidade}
                                                </TableCell>
                                                <TableCell>
                                                  {produto.descontoPercentual >
                                                  0
                                                    ? `${produto.descontoPercentual}%`
                                                    : produto.descontoMonetario >
                                                      0
                                                    ? formatCurrency(
                                                        parseFloat(
                                                          produto.descontoMonetario
                                                        )
                                                      )
                                                    : "0"}
                                                </TableCell>
                                                <TableCell>
                                                  {formatCurrency(
                                                    parseFloat(
                                                      produto.precoFinal
                                                    )
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  {formatCurrency(
                                                    parseFloat(
                                                      produto.repasseFornecedor
                                                    )
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            ))
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Paper>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          )}
        </Card>
                </Card>
      </Container>
    </Box>
  );
}

export default CaixaEmpresa;
