import React, { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  useMediaQuery,
  CircularProgress,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  ShoppingCart as ShoppingCartIcon,
  MonetizationOn as MonetizationOnIcon,
  Storefront as StorefrontIcon,
} from "@mui/icons-material";

// Função para chamadas ao backend com logs para depuração
const callBackendAPI = async (endpoint, method, body) => {
  console.log(`Calling API: ${endpoint} with body:`, body);
  try {
    const response = await fetch(endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Erro: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Erro ao chamar a API ${endpoint}:`, error);
    throw error;
  }
};

// Componente memoizado para evitar renderizações desnecessárias
const MemoizedCard = React.memo(({ titulo, valor, Icone, bgColor }) => (
  <Card style={{ backgroundColor: bgColor, color: "white", height: "100%" }}>
    <CardContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h6">{titulo}</Typography>
          <Typography variant="h4">{valor}</Typography>
        </Box>
        <Icone style={{ fontSize: 40, color: "white" }} />
      </Box>
    </CardContent>
  </Card>
));

const DashboardPage = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [totalPedidos, setTotalPedidos] = useState(0);
  const [totalCaixaEmpresa, setTotalCaixaEmpresa] = useState(0);
  const [totalProdutosEstoque, setTotalProdutosEstoque] = useState(0);
  const [vendasMensais, setVendasMensais] = useState([]);
  const empresaId = userDetails?.empresaId;
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Cache de dados no sessionStorage
  const carregarDados = useCallback(async () => {
    if (!empresaId) {
      console.error("Empresa ID não encontrado!");
      setError("Empresa ID não encontrado.");
      setIsLoading(false);
      return;
    }

    const cache = sessionStorage.getItem(`dashboard_${empresaId}`);
    if (cache) {
      const cachedData = JSON.parse(cache);
      setTotalPedidos(cachedData.totalPedidos);
      setTotalCaixaEmpresa(cachedData.totalCaixaEmpresa);
      setTotalProdutosEstoque(cachedData.totalProdutosEstoque);
      setVendasMensais(cachedData.vendasMensais);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const ano = new Date().getFullYear();
      const mes = new Date().getMonth() + 1;

      const [pedidosResponse, saldoResponse, estoqueResponse, vendasMensaisResponse] = await Promise.all([
        callBackendAPI("https://us-central1-dona-cora.cloudfunctions.net/calcularTotalPedidos", "POST", { empresaId, ano, mes }),
        callBackendAPI("https://us-central1-dona-cora.cloudfunctions.net/calcularSaldoAcumulado", "POST", { empresaId, ano, mes }),
        callBackendAPI("https://us-central1-dona-cora.cloudfunctions.net/calcularEstoqueTotal", "POST", { empresaId }),
        callBackendAPI("https://us-central1-dona-cora.cloudfunctions.net/obterVendasMensais", "POST", { empresaId })
      ]);

      setTotalPedidos(pedidosResponse.totalPedidos);
      setTotalCaixaEmpresa(saldoResponse.saldoAcumulado);
      setTotalProdutosEstoque(estoqueResponse.totalEstoque);
      setVendasMensais(vendasMensaisResponse.vendasMensais);

      const dataToCache = {
        totalPedidos: pedidosResponse.totalPedidos,
        totalCaixaEmpresa: saldoResponse.saldoAcumulado,
        totalProdutosEstoque: estoqueResponse.totalEstoque,
        vendasMensais: vendasMensaisResponse.vendasMensais,
      };
      sessionStorage.setItem(`dashboard_${empresaId}`, JSON.stringify(dataToCache));

    } catch (error) {
      console.error("Erro ao carregar dados do backend:", error);
      setError("Erro ao carregar dados do backend.");
    } finally {
      setIsLoading(false);
    }
  }, [empresaId]);

  useEffect(() => {
    if (empresaId) {
      carregarDados();
    }
  }, [empresaId, carregarDados]);

  // Função de logout
  const handleLogout = async () => {
    try {
      sessionStorage.removeItem(`dashboard_${empresaId}`); // Limpa o cache ao realizar logout
      await signOut(getAuth());
      navigate(`/login`);
    } catch (error) {
      console.error("Erro ao sair:", error);
    }
  };

  // Exibir loading enquanto os dados estão sendo carregados
  if (isLoading) {
    return (
      <Container maxWidth="xl">
        <CircularProgress />
      </Container>
    );
  }

  // Exibir erro, se houver
  if (error) {
    return (
      <Container maxWidth="xl">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  // Renderizar o dashboard com os dados do backend
  return (
      <Container
        maxWidth="xl"
        style={{
          marginTop: "20px",
          paddingLeft: isMdUp ? "230px" : "16px",
          paddingRight: "16px",
          maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", 
        }}
      >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: "20px", marginBottom: "20px" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" color="textPrimary">
                Bem-vindo(a) ao Dashboard, {userDetails?.empresaData?.nome || "Carregando..."}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<LogoutIcon />}
                onClick={handleLogout}
              >
                Sair
              </Button>
            </Box>
            <Typography variant="h6" color="textSecondary">
              Empresa: {userDetails?.empresaData?.nome || "Carregando..."} | Endereço: {userDetails?.empresaData?.endereco || "Carregando..."}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <MemoizedCard
            titulo="TOTAL DE PEDIDOS"
            valor={totalPedidos}
            Icone={ShoppingCartIcon}
            bgColor="#007bff"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <MemoizedCard
            titulo="SALDO ACUMULADO"
            valor={`R$ ${totalCaixaEmpresa.toFixed(2)}`}
            Icone={MonetizationOnIcon}
            bgColor="#28a745"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <MemoizedCard
            titulo="PRODUTOS EM ESTOQUE"
            valor={totalProdutosEstoque}
            Icone={StorefrontIcon}
            bgColor="#ffc107"
          />
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              Fluxo de Caixa
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={vendasMensais}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="mes" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="totalMensal" stroke="#28a745" name="Total Mensal" />
                <Line type="monotone" dataKey="totalRepasseFornecedores" stroke="#ff7300" name="Pagamentos a Fornecedores" />
                <Line type="monotone" dataKey="totalCustosOperacionais" stroke="#8884d8" name="Custos Operacionais" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DashboardPage;
