import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  collection,
  getDocs,
  setDoc,
  query,
  where,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { useTheme } from "@mui/material/styles";
import { db, storage } from "./firebase";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { jsPDF } from "jspdf";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Papa from "papaparse";
import debounce from 'lodash/debounce';
import JSZip from "jszip"; // Certifique-se de importar JSZip
import { saveAs } from 'file-saver';

const Cadastrodefornecedor = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;

  const [fornecedor, setFornecedor] = useState({
    id: "",
    nome: "",
    endereco: "", // Novo campo
    numero: "", // Novo campo
    bairro: "", // Novo campo
    uf: "", // Novo campo
    cidade: "", // Novo campo
    cep: "", // Novo campo
    contato: "",
    email: "",
    cpfCnpj: "",
    ieRg: "", // Novo campo
    porcentagem: "", // Campo já existente
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [fornecedoresEncontrados, setFornecedoresEncontrados] = useState([]);

  const debouncedSearch = debounce(async (nome) => {
    if (nome.trim().length > 0) {
      setLoading(true);
      try {
        const fornecedoresQuery = query(
          collection(db, "EMPRESAS", empresaId, "Cadastro de fornecedores"),
          where("nome", ">=", nome),
          where("nome", "<=", nome + "\uf8ff")
        );
        const querySnapshot = await getDocs(fornecedoresQuery);
        const fornecedoresList = [];
        querySnapshot.forEach((doc) => {
          fornecedoresList.push({ id: doc.id, ...doc.data() });
        });
        setFornecedoresEncontrados(fornecedoresList);
      } catch (error) {
        setError("Erro ao buscar fornecedores: " + error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setFornecedoresEncontrados([]);
    }
  }, 500);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFornecedor((prev) => ({ ...prev, [name]: value }));

    if (name === "nome") {
      debouncedSearch(value);
    }
  };

  const validateData = () => {
    if (!fornecedor.nome) {
      setError("O campo nome é obrigatório.");
      return false;
    }
    return true;
  };

  const verificarDuplicidade = async (nome, cpfCnpj) => {
    try {
      // Verificar se já existe um fornecedor com o mesmo nome
      const nomeQuery = query(
        collection(db, "EMPRESAS", empresaId, "Cadastro de fornecedores"),
        where("nome", "==", nome)
      );
      const nomeSnapshot = await getDocs(nomeQuery);

      // Verificar se já existe um fornecedor com o mesmo CPF/CNPJ
      const cpfCnpjQuery = query(
        collection(db, "EMPRESAS", empresaId, "Cadastro de fornecedores"),
        where("cpfCnpj", "==", cpfCnpj)
      );
      const cpfCnpjSnapshot = await getDocs(cpfCnpjQuery);

      if (!nomeSnapshot.empty) {
        setError("Já existe um fornecedor com o mesmo nome.");
        return false;
      }
      if (!cpfCnpjSnapshot.empty) {
        setError("Já existe um fornecedor com o mesmo CPF/CNPJ.");
        return false;
      }

      return true;
    } catch (error) {
      setError("Erro ao verificar duplicidade: " + error.message);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateData()) return;

    // Verificar duplicidade
    const isDuplicated = await verificarDuplicidade(fornecedor.nome, fornecedor.cpfCnpj);
    if (!isDuplicated) {
      return; // Se houver duplicidade, a execução para aqui
    }

    setLoading(true);
    let fornecedorRef;

    if (isEditing && fornecedor.id) {
      fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedor.id);
    } else {
      const fornecedorId = fornecedor.nome.trim().replace(/\s+/g, "-").toLowerCase();
      fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedorId);
      setFornecedor((prev) => ({ ...prev, id: fornecedorId }));
    }

    try {
      await setDoc(fornecedorRef, fornecedor, { merge: true });
      await gerarESalvarContrato(fornecedorRef); // Função para gerar o contrato PDF
      setSnackbarMessage(isEditing ? "Fornecedor atualizado com sucesso!" : "Fornecedor cadastrado com sucesso!");
      setOpenSnackbar(true);
      limparFormulario();
      setIsEditing(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  const gerarESalvarContrato = async (fornecedorRef, fornecedor) => {
    try {
      const contractText = await fetchContractText();

      if (!contractText || contractText === "Texto padrão do contrato não encontrado.") {
        throw new Error("Texto de contrato não disponível.");
      }

      const docPdf = new jsPDF();
      const margin = 20;
      const pageWidth = docPdf.internal.pageSize.getWidth();
      const maxLineWidth = pageWidth - margin * 2;
      let cursorY = margin;
      const lineHeight = 10;

      const dataAtual = new Date().toLocaleDateString();
      docPdf.setFontSize(12);
      docPdf.setFont("helvetica", "normal");
      docPdf.text(`Data: ${dataAtual}`, margin, cursorY);
      cursorY += lineHeight * 2;

      let textoPersonalizado = contractText
        .replace("{nomeFornecedor}", fornecedor.nome)
        .replace("{cpfCnpj}", fornecedor.cpfCnpj)
        .replace("{contato}", fornecedor.contato)
        .replace("{email}", fornecedor.email)
        .replace("{porcentagem}", fornecedor.porcentagem + "%")
        .replace("{porcentagemConsignataria}", (100 - parseInt(fornecedor.porcentagem, 10)) + "%");

      docPdf.setFontSize(16);
      docPdf.setFont("helvetica", "bold");
      docPdf.text(`Contrato de Consignação`, margin, cursorY);
      cursorY += lineHeight * 2;

      docPdf.setFontSize(12);
      docPdf.setFont("helvetica", "normal");

      const paragrafos = textoPersonalizado.split("\n\n");
      paragrafos.forEach((paragrafo) => {
        let linhas = docPdf.splitTextToSize(paragrafo, maxLineWidth);
        linhas.forEach((linha) => {
          docPdf.text(linha, margin, cursorY);
          cursorY += lineHeight;
          if (cursorY + lineHeight > docPdf.internal.pageSize.getHeight() - margin) {
            docPdf.addPage();
            cursorY = margin;
          }
        });
        cursorY += lineHeight;
      });

      const pdfBlob = docPdf.output("blob");

      const pdfRef = ref(storage, `${empresaId}/contratos_fornecedores/Contrato_${fornecedor.nome}.pdf`);
      await uploadBytes(pdfRef, pdfBlob);
      const pdfURL = await getDownloadURL(pdfRef);
      await setDoc(fornecedorRef, { contratoUrl: pdfURL }, { merge: true });

      return { nome: fornecedor.nome, pdfBlob }; // Retorna o blob do PDF gerado
    } catch (error) {
      console.error("Erro ao gerar e salvar o contrato:", error);
    }
  };
  const handleDownloadContrato = async () => {
    if (!fornecedor.id) {
      setError("Nenhum fornecedor selecionado.");
      return;
    }

    try {
      setLoading(true);
      const fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedor.id);
      const fornecedorDoc = await getDoc(fornecedorRef);

      if (fornecedorDoc.exists() && fornecedorDoc.data().contratoUrl) {
        const url = fornecedorDoc.data().contratoUrl;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Contrato_${fornecedor.nome}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setError("Contrato não encontrado para este fornecedor.");
      }
    } catch (error) {
      setError("Erro ao baixar o contrato: " + error.message);
    } finally {
      setLoading(false);
    }
  };


  const fetchContractText = async () => {
    try {
      const contratoRef = collection(db, "EMPRESAS", empresaId, "Contratos");
      const contratoSnapshot = await getDocs(contratoRef);
      let contractText = null;

      contratoSnapshot.forEach((doc) => {
        if (doc.exists()) {
          contractText = doc.data().contrato_fornecedor;
        }
      });

      if (!contractText) {
        throw new Error("Texto de contrato não encontrado.");
      }
      return contractText;
    } catch (error) {
      console.error("Erro ao buscar texto de contrato:", error);
      return "Texto padrão do contrato não encontrado.";
    }
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const fornecedores = results.data;
        const zip = new JSZip();
        setLoading(true);
        try {
          for (const fornecedor of fornecedores) {
            if (fornecedor.nome) {
              const fornecedorId = fornecedor.nome.trim().replace(/\s+/g, "-").toLowerCase();
              const fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedorId);

              const novoFornecedor = {
                nome: fornecedor.nome || "",
                endereco: fornecedor.endereco || "",
                numero: fornecedor.numero || "",
                bairro: fornecedor.bairro || "",
                uf: fornecedor.uf || "",
                cidade: fornecedor.cidade || "",
                cep: fornecedor.cep || "",
                contato: fornecedor.contato || "",
                email: fornecedor.email || "",
                cpfCnpj: fornecedor.cpfCnpj || "",
                ieRg: fornecedor.ieRg || "",
                porcentagem: fornecedor.porcentagem || "",
              };

              await setDoc(fornecedorRef, novoFornecedor, { merge: true });

              // Gerar contrato e adicionar ao ZIP
              const { nome, pdfBlob } = await gerarESalvarContrato(fornecedorRef, novoFornecedor);
              zip.file(`Contrato_${nome}.pdf`, pdfBlob);
            }
          }

          // Gerar o arquivo ZIP e salvar
          const zipBlob = await zip.generateAsync({ type: "blob" });
          saveAs(zipBlob, "contratos_fornecedores.zip");

          setSnackbarMessage("Fornecedores migrados e contratos gerados com sucesso!");
          setOpenSnackbar(true);
        } catch (error) {
          setError("Erro ao migrar fornecedores: " + error.message);
        } finally {
          setLoading(false);
        }
      },
      error: (error) => {
        setError("Erro ao processar CSV: " + error.message);
      },
    });
  };


  const handleDelete = async () => {
    if (!fornecedor.id) {
      setError("Nenhum fornecedor selecionado para exclusão.");
      return;
    }

    try {
      setLoading(true);
      const fornecedorRef = doc(
        db,
        "EMPRESAS",
        empresaId,
        "Cadastro de fornecedores",
        fornecedor.id
      );
      await deleteDoc(fornecedorRef);
      setSnackbarMessage("Fornecedor excluído com sucesso!");
      setOpenSnackbar(true);
      limparFormulario();
    } catch (error) {
      setError("Erro ao excluir fornecedor. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const limparFormulario = () => {
    setFornecedor({
      id: "",
        nome: "",
        endereco: "", // Novo campo
        numero: "", // Novo campo
        bairro: "", // Novo campo
        uf: "", // Novo campo
        cidade: "", // Novo campo
        cep: "", // Novo campo
        contato: "",
        email: "",
        cpfCnpj: "",
        ieRg: "", // Novo campo
        porcentagem: "", // Campo já existente
    });
    setIsEditing(false);
    setError("");
    setFornecedoresEncontrados([]);
  };

  const selecionarParaEdicao = (fornecedorSelecionado) => {
    setFornecedor(fornecedorSelecionado);
    setIsEditing(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  useEffect(() => {
    const loadFornecedores = async () => {
      const fornecedoresQuery = query(
        collection(db, "EMPRESAS", empresaId, "Cadastro de fornecedores")
      );
      const querySnapshot = await getDocs(fornecedoresQuery);
      const fornecedoresList = [];
      querySnapshot.forEach((doc) => {
        fornecedoresList.push({ id: doc.id, ...doc.data() });
      });
      console.log("Fornecedores encontrados: ", fornecedoresList);
    };

    loadFornecedores();
  }, []);

return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: theme.spacing(1),
        ml: { xs: 0, md: "240px" }, // Margem para a sidebar
        width: "100%", 
        maxWidth: { xs: "95vw", sm: "90vw", md: "80vw", lg: "70vw", xl: "60vw" }, // Ajusta o maxWidth conforme o tamanho da tela
        margin: "0 auto", // Centraliza o conteúdo na tela
      }}
    >

    <Paper
      elevation={3}
      sx={{
        flex: 1,
        p: 2,
        margin: "auto",
        maxWidth: "100%",
        minWidth: { xs: "100%", md: "140%" }, // Ajuste para telas menores
        overflow: "auto",
      }}
    >
      <Typography component="h1" variant="h6" align="center" gutterBottom>
        Cadastro e Consulta de Fornecedor
      </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
            {error && <Alert severity="error">{error}</Alert>}

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    fullWidth
                    id="nome"
                    label="Nome do Fornecedor"
                    name="nome"
                    value={fornecedor.nome}
                    onChange={handleChange}
                    size="small"
                    inputProps={{ style: { textTransform: 'uppercase' } }} // Sempre em caixa alta
                  />
                </Grid>
        


              {/* Renderizando a lista de fornecedores encontrados */}
              {fornecedoresEncontrados.length > 0 && (
                <Grid item xs={12}>
                  <List dense>
                    {fornecedoresEncontrados.map((fornecedor) => (
                      <ListItem
                        key={fornecedor.id}
                        button
                        onClick={() => selecionarParaEdicao(fornecedor)}
                      >
                        <ListItemText
                          primary={fornecedor.nome}
                          secondary={`CPF/CNPJ: ${fornecedor.cpfCnpj || "Não informado"}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  margin="dense"
                  fullWidth
                  id="cpfCnpj"
                  label="CPF/CNPJ"
                  name="cpfCnpj"
                  value={fornecedor.cpfCnpj}
                  onChange={handleChange}
                  size="small"
                />
              </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              fullWidth
              id="contato"
              label="Contato (Telefone)"
              name="contato"
              value={fornecedor.contato}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              fullWidth
              id="email"
              label="E-mail do Fornecedor"
              name="email"
              value={fornecedor.email}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              fullWidth
              id="endereco"
              label="Endereço"
              name="endereco"
              value={fornecedor.endereco}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              margin="dense"
              fullWidth
              id="numero"
              label="Número"
              name="numero"
              value={fornecedor.numero}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              margin="dense"
              fullWidth
              id="bairro"
              label="Bairro"
              name="bairro"
              value={fornecedor.bairro}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              margin="dense"
              fullWidth
              id="uf"
              label="UF"
              name="uf"
              value={fornecedor.uf}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              margin="dense"
              fullWidth
              id="cidade"
              label="Cidade"
              name="cidade"
              value={fornecedor.cidade}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              margin="dense"
              fullWidth
              id="cep"
              label="CEP"
              name="cep"
              value={fornecedor.cep}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              margin="dense"
              fullWidth
              id="ieRg"
              label="IE/RG"
              name="ieRg"
              value={fornecedor.ieRg}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              fullWidth
              id="chavePix"
              label="Chave Pix"
              name="chavePix"
              value={fornecedor.chavePix}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              margin="dense"
              fullWidth
              id="agencia"
              label="Agência"
              name="agencia"
              value={fornecedor.agencia}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              margin="dense"
              fullWidth
              id="conta"
              label="Conta Corrente"
              name="conta"
              value={fornecedor.conta}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              fullWidth
              id="porcentagem"
              label="Porcentagem de Comissão (%)"
              name="porcentagem"
              type="number"
              value={fornecedor.porcentagem}
              onChange={handleChange}
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" component="label">
              Upload CSV
              <input type="file" hidden accept=".csv" onChange={handleCSVUpload} />
            </Button>
            <Typography variant="caption" display="block" gutterBottom>
              O arquivo CSV deve conter as colunas: nome, contato, cpfCnpj, chavePix, agencia, conta, email, porcentagem. A ordem das colunas não importa, mas os nomes devem ser exatos.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant={isEditing ? "outlined" : "contained"}
              color={isEditing ? "primary" : "secondary"}
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : isEditing ? "Editar" : "Cadastrar"}
            </Button>
            {isEditing && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 1, mb: 2 }}
                onClick={handleDownloadContrato}
                disabled={loading}
              >
                Baixar Contrato
              </Button>
            )}

            {isEditing && (
              <Button
                fullWidth
                variant="contained"
                color="error"
                sx={{ mt: 1, mb: 2 }}
                disabled={loading}
                onClick={handleDelete}
              >
                Excluir Fornecedor
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>

    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={snackbarMessage}
    />
  </Box>
);
};
export default Cadastrodefornecedor;
