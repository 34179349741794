import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  Container,
  Card,
  CardContent,
  Typography,
  InputAdornment,
  InputLabel,
  Grid,
  Snackbar,
  useMediaQuery,
  FormControl,
  List,
  ListItem,
  ListItemSecondaryAction,
  Divider,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDocs,
  getDoc
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const Orcamento = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedor, setFornecedor] = useState("");
  const [descricao, setDescricao] = useState("");
  const [tamanho, setTamanho] = useState("");
  const [marca, setMarca] = useState("");
  const [categoria, setCategoria] = useState("");
  const [dataEntrada, setDataEntrada] = useState("");
  const [valorVenda, setValorVenda] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [isFornecedorCora, setIsFornecedorCora] = useState(false);
  const [valorCompra, setValorCompra] = useState("");
  const [orcamento, setOrcamento] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [dialogValues, setDialogValues] = useState({});
  const [categorias, setCategorias] = useState([]);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [savedOrcamentoDoc, setSavedOrcamentoDoc] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [marcas, setMarcas] = useState([]); // Novo estado para marcas

  useEffect(() => {
    const fetchFornecedores = async () => {
      const querySnapshot = await getDocs(
        collection(
          getFirestore(),
          "EMPRESAS",
          empresaId,
          "Cadastro de fornecedores"
        )
      );
      const fornecedoresData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        nome: doc.data().nome
      }));
      setFornecedores(fornecedoresData);
    };

    const fetchCategorias = async () => {
      try {
        const empresaDocRef = doc(getFirestore(), "EMPRESAS", empresaId);
        const empresaDoc = await getDoc(empresaDocRef);
        if (empresaDoc.exists()) {
          setCategorias(empresaDoc.data().Categorias || []);
          setMarcas(empresaDoc.data().Marcas || []); // Carregar marcas
        } else {
          console.error("Documento da empresa não encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    };

    if (empresaId) {
      fetchFornecedores();
      fetchCategorias();
    }
  }, [empresaId]);

  const handleSubmit = e => {
    e.preventDefault();
    const today = new Date().toISOString().split("T")[0];
    const peca = {
      descricao,
      tamanho,
      marca,
      categoria,
      dataEntrada: dataEntrada || today,
      valorVenda: parseFloat(
        (valorVenda || "0").replace(/\./g, "").replace(",", ".")
      ), // Salva como número
      status: "Pendente",
      quantidade: parseInt(quantidade, 10),
      imageUrl: uploadedImageUrl,
      fornecedor: isFornecedorCora ? "Fornecedor Próprio" : fornecedor,
      valorCompra: isFornecedorCora
        ? parseFloat((valorCompra || "0").replace(/\./g, "").replace(",", "."))
        : ""
    };
    setOrcamento([...orcamento, peca]);
    setSnackbarMessage("Peça adicionada ao orçamento.");
    setOpenSnackbar(true);
    limparCampos();
  };

  const handleImageUpload = async e => {
    const file = e.target.files[0];
    if (!file) {
      setImagePreview(null);
      setUploadedImageUrl("");
      return;
    }
    const reader = new FileReader();
    reader.onloadend = async () => {
      setImagePreview(reader.result);

      const storage = getStorage();
      const storageRef = ref(storage, `${empresaId}/imagens/${file.name}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setUploadedImageUrl(downloadURL);
      } catch (error) {
        console.error("Erro ao enviar a imagem:", error);
      }
    };
    reader.readAsDataURL(file);
  };

  const showLoadingDialog = message => {
    setLoadingMessage(message);
    setOpenLoadingDialog(true);
  };

  const closeLoadingDialog = () => {
    setOpenLoadingDialog(false);
  };

  const handleSalvarOrcamento = async () => {
    // Inicia o processo com uma exibição clara de loading
    showLoadingDialog("Criando Orçamento...");
    let idOrcamento;
    try {
      const db = getFirestore();
      const numeroOrcamento = gerarNumeroOrcamento();
      const dataAtual = new Date();
      idOrcamento = `${numeroOrcamento}`;

      const orcamentoDoc = {
        fornecedor: fornecedor || "Fornecedor Próprio",
        data: dataAtual.toISOString(),
        pecas: orcamento.map((peca) => ({
          ...peca,
          dataEntrada: peca.dataEntrada || dataAtual.toISOString().split("T")[0],
          valorVenda: parseFloat(
            (peca.valorVenda || "0").toString().replace(/\./g, "").replace(",", ".")
          ), // Salva como número
          valorCompra: peca.valorCompra
            ? parseFloat(
                (peca.valorCompra || "0").toString().replace(/\./g, "").replace(",", ".")
              )
            : 0, // Salva como número
        })),
        numero: numeroOrcamento,
        id: idOrcamento,
        descricaoFornecedor: "",
        enderecoFornecedor: "",
        status: "Pendente",
        contatoFornecedor: "",
      };

      // Usa uma transação para garantir que as operações no Firestore sejam atômicas
      await setDoc(doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", idOrcamento), orcamentoDoc);

      setSavedOrcamentoDoc(orcamentoDoc);

      // Gera o PDF
      showLoadingDialog("Criando PDF...");
      const pdfBlob = await gerarPDF(orcamentoDoc);

      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);

      // Envia o e-mail ao fornecedor
      showLoadingDialog("Enviando Email de orçamento ao fornecedor...");
      await enviarEmailComPDF(fornecedor, pdfBlob);

      setSnackbarMessage("Orçamento salvo e email enviado com sucesso.");
      setOpenSnackbar(true);

      // Limpa os campos
      setOrcamento([]);
      setFornecedor("");
      setImagePreview(null);
      setOpenPdfModal(true);

    } catch (error) {
      console.error("Erro ao salvar orçamento:", error);
      // Remove o orçamento criado se houver erro
      if (idOrcamento) {
        await deleteDoc(doc(getFirestore(), "EMPRESAS", empresaId, "Lista de Orçamentos", idOrcamento));
      }
      setSnackbarMessage("Erro ao salvar orçamento: " + error.message);
      setOpenSnackbar(true);
    } finally {
      closeLoadingDialog();
    }
  };


  const gerarNumeroOrcamento = () => {
    const inicialFornecedor = fornecedor.slice(0, 1, 2);
    const numeroAleatorio = Math.floor(1000 + Math.random() * 9000);
    return `${inicialFornecedor}${numeroAleatorio}`;
  };

  const gerarPDF = async (orcamentoDoc) => {
    return new Promise(async (resolve, reject) => {
      const db = getFirestore(); // Mova isso para onde você usar o Firestore
      const jsPdfDoc = new jsPDF({ orientation: "landscape" });

      jsPdfDoc.setFontSize(18);
      jsPdfDoc.text(`${empresaId}`, 14, 20);
      jsPdfDoc.setFontSize(11);
      jsPdfDoc.text(`Orçamento: ${orcamentoDoc.numero}`, 14, 25);
      jsPdfDoc.text(`Fornecedor: ${orcamentoDoc.fornecedor}`, 14, 30);

      const agora = new Date();
      const dataHora = agora.toLocaleString("pt-BR");
      jsPdfDoc.text(`Data/Hora: ${dataHora}`, 14, 34);

      const colunas = [
        { header: "Item", dataKey: "item" },
        { header: "Descrição", dataKey: "descricao" },
        { header: "Quantidade", dataKey: "quantidade" },
        { header: "Categoria", dataKey: "categoria" },
        { header: "Tamanho", dataKey: "tamanho" },
        { header: "Marca", dataKey: "marca" },
        { header: "Fornecedor", dataKey: "fornecedor" },
        { header: "Data de Entrada", dataKey: "dataEntrada" },
        { header: "Valor de Venda Previsto", dataKey: "valorVenda" },
        { header: "Valor Comissão", dataKey: "valorComissao" }
      ];

      const linhas = [];

      for (const [index, peca] of orcamentoDoc.pecas.entries()) {
        let porcentagemComissao = 50; // Valor padrão

        if (peca.fornecedor && peca.fornecedor !== "Fornecedor Próprio") {
          const fornecedorRef = doc(
            db,
            `EMPRESAS/${empresaId}/Cadastro de fornecedores/${peca.fornecedor}`
          );
          const fornecedorSnap = await getDoc(fornecedorRef);

          if (fornecedorSnap.exists()) {
            const porcentagem = fornecedorSnap.data().porcentagem;
            if (porcentagem !== undefined) {
              porcentagemComissao = porcentagem;
            }
          }
        }

        const valorVendaTotal =
          peca.quantidade *
          parseFloat(
            (peca.valorVenda || "0")
              .toString()
              .replace(/\./g, "")
              .replace(",", ".")
          );
        const valorComissao = valorVendaTotal * (porcentagemComissao / 100);

        linhas.push({
          item: index + 1,
          descricao: peca.descricao,
          quantidade: peca.quantidade,
          categoria: peca.categoria,
          tamanho: peca.tamanho,
          marca: peca.marca,
          fornecedor: peca.fornecedor,
          dataEntrada: peca.dataEntrada,
          valorVenda: `R$ ${valorVendaTotal.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`,
          valorComissao: `R$ ${valorComissao.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        });

        await new Promise(resolve => setTimeout(resolve, 500)); // Simulação de atraso para renderizar PDF
      }

      jsPdfDoc.autoTable(colunas, linhas, {
        startY: 35,
        margin: { horizontal: 10 },
        styles: { overflow: "linebreak" },
        bodyStyles: { valign: "top" },
        theme: "striped",
        showHead: "everyPage",
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: "auto" },
          2: { cellWidth: 30 },
          3: { cellWidth: 40 },
          8: { cellWidth: 40 },
          9: { cellWidth: 40 }
        }
      });

      const valorPrevistoRecebimento = linhas.reduce(
        (acc, curr) =>
          acc +
          parseFloat(
            curr.valorComissao
              .replace("R$ ", "")
              .replace(/\./g, "")
              .replace(",", ".")
          ),
        0
      );

      if (jsPdfDoc.lastAutoTable.finalY) {
        jsPdfDoc.text(
          `Valor Previsto para Pagamento ao Fornecedor: R$ ${valorPrevistoRecebimento.toLocaleString(
            "pt-BR",
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`,
          14,
          jsPdfDoc.lastAutoTable.finalY + 10
        );
      }

      const pdfBlob = jsPdfDoc.output("blob");
      resolve(pdfBlob);
    });
  };



  const enviarEmailComPDF = async (fornecedor, pdfBlob) => {
    const fornecedorRef = doc(
      getFirestore(),
      "EMPRESAS",
      empresaId,
      "Cadastro de fornecedores",
      fornecedor
    );
    const fornecedorDoc = await getDoc(fornecedorRef);

    if (!fornecedorDoc.exists()) {
      setSnackbarMessage("Erro: Fornecedor não encontrado.");
      setOpenSnackbar(true);
      return;
    }

    const fornecedorData = fornecedorDoc.data();
    const fornecedorEmail = fornecedorData.email;

    if (!fornecedorEmail) {
      setSnackbarMessage("Erro: Fornecedor não possui email cadastrado.");
      setOpenSnackbar(true);
      return;
    }

    const attachmentBase64 = await pdfBlobToBase64(pdfBlob);
    console.log("Attachment Base64:", attachmentBase64);

    const mailData = {
      to: fornecedorEmail,
      subject: `Orçamento ${empresaId} para o fornecedor ${fornecedor}`,
      text: "Segue em anexo o orçamento solicitado.",
      attachment: attachmentBase64,
      filename: `Orcamento_${empresaId}_${fornecedor}.pdf`
    };

    console.log("Mail data:", mailData);

    try {
      const response = await fetch(
        "https://us-central1-dona-cora.cloudfunctions.net/sendEmailWithPDF",
        {
          method: "POST",
          body: JSON.stringify(mailData),
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      console.log("Response:", response);

      if (!response.ok) {
        const responseText = await response.text();
        console.error("Response text:", responseText);
        throw new Error("Erro ao enviar email.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao enviar email: " + error.message);
      setOpenSnackbar(true);
    }
  };

  const pdfBlobToBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCurrencyChange = (event, setFunction) => {
    let valor = event.target.value.replace(/\D/g, "");
    valor = (valor / 100).toFixed(2) + "";
    valor = valor.replace(".", ",");
    valor = valor.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    valor = valor.replace(/(\d)(\d{3}),/g, "$1.$2,");
    setFunction(valor);
  };

  const handleNovoOrcamento = () => {
    setOrcamento([]);
    setFornecedor("");
    setDescricao("");
    setTamanho("");
    setMarca("");
    setCategoria("");
    setDataEntrada("");
    setValorVenda("");
    setQuantidade("");
    setValorCompra("");
    setIsFornecedorCora(false);
  };

  const handleDeleteItem = index => {
    const updatedOrcamento = [...orcamento];
    updatedOrcamento.splice(index, 1);
    setOrcamento(updatedOrcamento);
  };

  const handleEditItem = index => {
    setEditIndex(index);
    setDialogValues(orcamento[index]);
    setOpenDialog(true);
  };

  const handleDialogChange = event => {
    const { name, value } = event.target;
    setDialogValues(prev => ({ ...prev, [name]: value }));
  };

  const atualizarItem = () => {
    const novoOrcamento = [...orcamento];
    novoOrcamento[editIndex] = { ...novoOrcamento[editIndex], ...dialogValues };
    setOrcamento(novoOrcamento);
    setOpenDialog(false);
    setDialogValues({});
  };

  const limparCampos = () => {
    setDescricao("");
    setTamanho("");
    setMarca("");
    setCategoria("");
    setDataEntrada("");
    setValorVenda("");
    setQuantidade("");
    setValorCompra("");
    setImagePreview(null);
    setUploadedImageUrl("");
  };

  const handleClosePdfModal = () => {
    setOpenPdfModal(false);
    setPdfUrl("");
    setSavedOrcamentoDoc(null);
  };

  return (
        <Container
          maxWidth="xl"
          style={{
            marginTop: "20px",
            paddingLeft: isMdUp ? "220px" : "16px",
            paddingRight: "16px",
            maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
          }}
        >
      <Card
        style={{
          marginBottom: "30px",
          borderRadius: "15px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)"
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            Cadastro de Orçamento
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Fornecedor</InputLabel>
                  <Select
                    value={fornecedor}
                    onChange={e => setFornecedor(e.target.value)}
                    label="Fornecedor"
                  >
                    <MenuItem value="" disabled>
                      Escolher Fornecedor
                    </MenuItem>
                    {fornecedores.map(forn => (
                      <MenuItem key={forn.id} value={forn.id}>
                        {forn.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Descrição do Produto"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="image-upload"
                  type="file"
                  capture="environment"
                  onChange={handleImageUpload}
                />
                <label htmlFor="image-upload">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<PhotoCamera />}
                  >
                    Upload / Câmera
                  </Button>
                </label>
                {imagePreview && (
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  </div>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  freeSolo
                  options={marcas.map(option => option)}
                  inputValue={marca}
                  onInputChange={(event, newInputValue) => {
                    setMarca(newInputValue.toUpperCase());
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Marca"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Tamanho"
                  value={tamanho}
                  onChange={e => setTamanho(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Categoria</InputLabel>
                  <Select
                    value={categoria}
                    label="Categoria"
                    onChange={e => setCategoria(e.target.value)}
                  >
                    {categorias.map((cat, index) => (
                      <MenuItem key={index} value={cat}>
                        {cat}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Data de Entrada"
                  type="date"
                  value={dataEntrada}
                  onChange={e => setDataEntrada(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Quantidade"
                  type="number"
                  value={quantidade}
                  onChange={e => setQuantidade(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Valor da Peça (Venda)"
                  type="text"
                  value={valorVenda}
                  onChange={e => handleCurrencyChange(e, setValorVenda)}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Adicionar Peça ao Orçamento
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSalvarOrcamento}
                  style={{ marginLeft: "10px" }}
                >
                  Salvar Orçamento
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleNovoOrcamento}
                  style={{ marginLeft: "10px" }}
                >
                  Novo Orçamento
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <Card
        style={{
          marginTop: "30px",
          borderRadius: "15px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)"
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            Itens do Orçamento
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Itens do Orçamento" />
              <Typography
                style={{
                  marginRight: "auto",
                  textAlign: "right",
                  fontWeight: "bold"
                }}
              >
                Ações
              </Typography>
            </ListItem>
            <Divider component="li" />
            {orcamento.map((peca, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={peca.descricao}
                    secondary={`Quantidade: ${peca.quantidade} | Venda: R$${peca.valorVenda} | Compra: R$${peca.valorCompra} | Marca: ${peca.marca} | Tamanho: ${peca.tamanho} | Categoria: ${peca.categoria} | Entrada: ${peca.dataEntrada}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteItem(index)}
                      style={{ color: "red" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleEditItem(index)}
                      style={{ color: "green" }}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {index < orcamento.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Editar Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Atualize os campos necessários e clique em salvar.
          </DialogContentText>
          <TextField
            margin="dense"
            name="descricao"
            label="Descrição"
            fullWidth
            value={dialogValues.descricao || ""}
            onChange={handleDialogChange}
          />
          <TextField
            margin="dense"
            name="quantidade"
            label="Quantidade"
            fullWidth
            value={dialogValues.quantidade || ""}
            onChange={handleDialogChange}
          />
          <TextField
            margin="dense"
            name="categoria"
            label="Categoria"
            fullWidth
            value={dialogValues.categoria || ""}
            onChange={handleDialogChange}
          />
          <TextField
            margin="dense"
            name="tamanho"
            label="Tamanho"
            fullWidth
            value={dialogValues.tamanho || ""}
            onChange={handleDialogChange}
          />
          <TextField
            margin="dense"
            name="marca"
            label="Marca"
            fullWidth
            value={dialogValues.marca || ""}
            onChange={handleDialogChange}
          />
          <TextField
            margin="dense"
            name="dataEntrada"
            label="Data de Entrada"
            fullWidth
            value={dialogValues.dataEntrada || ""}
            onChange={handleDialogChange}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            name="valorVenda"
            label="Valor de Venda"
            fullWidth
            value={dialogValues.valorVenda || ""}
            onChange={e =>
              handleCurrencyChange(e, value =>
                setDialogValues(prev => ({ ...prev, valorVenda: value }))
              )
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={atualizarItem} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openLoadingDialog}>
        <DialogContent>
          <DialogContentText>{loadingMessage}</DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={openPdfModal} onClose={handleClosePdfModal}>
        <DialogTitle>Orçamento Salvo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            O orçamento foi salvo e enviado por e-mail. Você pode baixar o PDF
            clicando no botão abaixo.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            href={pdfUrl}
            download={`Orçamento-${savedOrcamentoDoc?.numero}.pdf`}
          >
            Baixar PDF
          </Button>
          <Button onClick={handleClosePdfModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Orcamento;
