import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import { useTheme } from "@mui/material/styles";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc
} from "firebase/firestore";
import {
  Container,
  Grid,
  Button,
  IconButton,
  useMediaQuery,
  TextField,
  FormControlLabel,
  Box,
  Typography,
  MenuItem,
  Select,
  Checkbox,
  Modal,
  Card,
  CardContent
} from "@mui/material"; // Certifique-se de importar Card e CardContent
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PrintIcon from "@mui/icons-material/Print";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import * as XLSX from "xlsx";
import { FixedSizeList as ListVirtualized } from "react-window";

// Função para carregar produtos e fornecedores do Firestore
const carregarProdutosEFornecedores = async (userDetails, setProdutos, setFornecedores, setCategorias, setMarcas) => {
  if (userDetails && userDetails.empresaId) {
    const querySnapshot = await getDocs(
      collection(db, "EMPRESAS", userDetails.empresaId, "Cadastro de Peças", "dados", "Estoque Ativo")
    );
    const produtosData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setProdutos(produtosData);

    const fornecedoresUnicos = [...new Set(produtosData.map((produto) => produto.fornecedor))];
    const categoriasUnicas = [...new Set(produtosData.map((produto) => produto.categoria))];
    const marcasUnicas = [...new Set(produtosData.map((produto) => produto.marca))];

    setFornecedores(fornecedoresUnicos);
    setCategorias(categoriasUnicas);
    setMarcas(marcasUnicas);
  }
};


// Função utilitária para gerar a etiqueta
const gerarConteudoEtiqueta = async ({ codigo, descricao, tamanho, marca, categoria, valorVenda }) => {
  let canvas = document.createElement("canvas");
  JsBarcode(canvas, codigo, { format: "CODE39", width: 2, height: 40 });
  let codigoBarras = canvas.toDataURL("image/png");

  const qrCode = await QRCode.toDataURL(codigo);

  return `
    <div style="font-family: Arial, sans-serif; text-align: center; margin-bottom: 20px;">
      <p><strong>Código da Peça:</strong> ${codigo}</p>
      <p><strong>Descrição:</strong> ${descricao}</p>
      <p><strong>Tamanho:</strong> ${tamanho}</p>
      <p><strong>Marca:</strong> ${marca}</p>
      <p><strong>Categoria:</strong> ${categoria}</p>
      <p><strong>Valor de Venda:</strong> R$ ${valorVenda}</p>
      <img src="${codigoBarras}" alt="Código de Barras"/>
      <img src="${qrCode}" alt="QR Code"/>
    </div>
  `;
};

const gerarEtiquetasMultiplas = async (produtosSelecionados) => {
  if (!Array.isArray(produtosSelecionados) || produtosSelecionados.length === 0) {
    console.error("Nenhum produto selecionado para gerar etiquetas.");
    return;
  }

  let etiquetas = '';

  for (let produto of produtosSelecionados) {
    const { codigoPeca, descricao, tamanho, marca, categoria, valorVenda } = produto;

    const etiqueta = await gerarConteudoEtiqueta({
      codigo: codigoPeca,
      descricao,
      tamanho,
      marca,
      categoria,
      valorVenda,
    });

    etiquetas += etiqueta;
  }

  let janelaImpressao = window.open("", "_blank", "width=800,height=600");
  if (janelaImpressao) {
    janelaImpressao.document.write(`<html><body>${etiquetas}</body></html>`);
    janelaImpressao.document.close();
    janelaImpressao.focus();
    setTimeout(() => {
      janelaImpressao.print();
      janelaImpressao.close();
    }, 500);
  } else {
    console.error("A janela de impressão não foi criada. Popup bloqueado?");
  }
};

const GerenciarProdutos = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const [produtos, setProdutos] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
 
  const [busca, setBusca] = useState("");
  const [produtosFiltrados, setProdutosFiltrados] = useState([]);
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [produtoEditavel, setProdutoEditavel] = useState(null);
  const [desconto, setDesconto] = useState(0);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [selectAll, setSelectAll] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [filtro, setFiltro] = useState({ fornecedor: "", categoria: "", marca: "", codigoPeca: "" });

  const [selectedFields, setSelectedFields] = useState({
    categoria: true,
    codigoPeca: true,
    dataEntrada: true,
    descricao: true,
    fornecedor: true,
    id: true,
    imageUrl: true,
    marca: true,
    quantidade: true,
    tamanho: true,
    valorCompra: true,
    valorVenda: true,
  });

  useEffect(() => {
    if (userDetails) {
      carregarProdutosEFornecedores(userDetails, setProdutos, setFornecedores, setCategorias, setMarcas);
    }
  }, [userDetails]);

  useEffect(() => {
    const filtrarProdutos = () => {
      let filtrados = produtos.filter(produto => {
        const fornecedorValido = filtro.fornecedor ? produto.fornecedor === filtro.fornecedor : true;
        const categoriaValida = filtro.categoria ? produto.categoria === filtro.categoria : true;
        const marcaValida = filtro.marca ? produto.marca === filtro.marca : true;
        const codigoPecaValido = filtro.codigoPeca ? produto.codigoPeca.includes(filtro.codigoPeca) : true;
        const descricaoValida = produto.descricao.toLowerCase().includes(busca.toLowerCase());

        return fornecedorValido && categoriaValida && marcaValida && codigoPecaValido && descricaoValida;
      });
      setProdutosFiltrados(filtrados);
    };

    filtrarProdutos();
  }, [busca, filtro, produtos]);



  const handleSelecionarProduto = produto => {
    setProdutosSelecionados(prev => {
      const exists = prev.find(p => p.id === produto.id);
      if (exists) {
        return prev.filter(p => p.id !== produto.id);
      } else {
        return [...prev, produto];
      }
    });
  };

  const handleEditarProduto = produto => {
    setProdutoEditavel(produto);
    setModalOpen(true);
  };

  const handleSelecionarTodos = () => {
    if (selectAll) {
      setProdutosSelecionados([]);
    } else {
      setProdutosSelecionados(produtosFiltrados);
    }
    setSelectAll(!selectAll);
  };

  const handleSalvarEdicao = async () => {
    const produtoRef = doc(db, "EMPRESAS", userDetails.empresaId, "Cadastro de Peças", "dados", "Estoque Ativo", produtoEditavel.id);
    await updateDoc(produtoRef, { ...produtoEditavel });
    setModalOpen(false);
    setProdutoEditavel(null);
    carregarProdutosEFornecedores(userDetails, setProdutos, setFornecedores);
  };

  const handleExcluirProduto = async produto => {
    const produtoRef = doc(db, "EMPRESAS", userDetails.empresaId, "Cadastro de Peças", "dados", "Estoque Ativo", produto.id);
    await deleteDoc(produtoRef);
    carregarProdutosEFornecedores(userDetails, setProdutos, setFornecedores);
  };

  const handleAplicarDesconto = (percentual, fornecedor = null) => {
    const produtosParaDescontar = fornecedor ? produtos.filter(p => p.fornecedor === fornecedor) : produtosSelecionados;
    produtosParaDescontar.forEach(async produto => {
      const novoValorVenda = produto.valorVenda - (produto.valorVenda * (percentual / 100));
      const produtoRef = doc(db, "EMPRESAS", userDetails.empresaId, "Cadastro de Peças", "dados", "Estoque Ativo", produto.id);
      await updateDoc(produtoRef, { valorVenda: novoValorVenda });
    });
    carregarProdutosEFornecedores(userDetails, setProdutos, setFornecedores);
  };

  const handleExportarExcel = () => {
    const worksheetData = produtosSelecionados.map(p => {
      const data = {};
      for (const field in selectedFields) {
        if (selectedFields[field]) {
          data[field] = p[field];
        }
      }
      return data;
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Produtos");
    XLSX.writeFile(workbook, "estoque.xlsx");
    setExportModalOpen(false);
  };

  const renderModalExport = () => (
    <Modal open={exportModalOpen} onClose={() => setExportModalOpen(false)}>
      <Card sx={{ width: 400, margin: "auto", mt: 10, p: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Selecione os Campos para Exportar
          </Typography>
          <Grid container spacing={1}>
            {Object.keys(selectedFields).map((field) => (
              <Grid item xs={6} key={field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFields[field]}
                      onChange={() => setSelectedFields({ ...selectedFields, [field]: !selectedFields[field] })}
                    />
                  }
                  label={field}
                />
              </Grid>
            ))}
          </Grid>
          <Button variant="contained" color="primary" fullWidth onClick={handleExportarExcel}>
            Exportar
          </Button>
        </CardContent>
      </Card>
    </Modal>
  );

  // Modal de Edição de Produto
  const renderModalEditarProduto = () => (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Box sx={{
        padding: 4,
        backgroundColor: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24
      }}>
        <Typography variant="h6">Editar Produto</Typography>
        <TextField
          fullWidth
          label="Descrição"
          value={produtoEditavel?.descricao || ""}
          onChange={(e) => setProdutoEditavel({ ...produtoEditavel, descricao: e.target.value })}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Valor de Venda"
          value={produtoEditavel?.valorVenda || ""}
          onChange={(e) => setProdutoEditavel({ ...produtoEditavel, valorVenda: e.target.value })}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Quantidade"
          value={produtoEditavel?.quantidade || ""}
          onChange={(e) => setProdutoEditavel({ ...produtoEditavel, quantidade: e.target.value })}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleSalvarEdicao}>Salvar</Button>
      </Box>
    </Modal>
  );

  const Row = ({ index, style }) => {
    const produto = produtosFiltrados[index];

    return (
      <div style={style}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1}>
            <Checkbox checked={produtosSelecionados.includes(produto)} onChange={() => handleSelecionarProduto(produto)} />
          </Grid>
          <Grid item xs={3}>
            {produto.descricao.length > 20 ? `${produto.descricao.substring(0, 20)}...` : produto.descricao}
          </Grid>
          <Grid item xs={2.5}>
            {produto.fornecedor}
          </Grid>
          <Grid item xs={1}>
            {produto.codigoPeca}
          </Grid>
          <Grid item xs={2}>
            R$ {typeof produto.valorVenda === 'number' ? produto.valorVenda.toFixed(2) : produto.valorVenda}
          </Grid>
          <Grid item xs={1}>
            {produto.quantidade}
          </Grid>
          <Grid item xs={1}>
            <IconButton color="primary" onClick={() => handleEditarProduto(produto)}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => handleExcluirProduto(produto)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  };

return (
        <Container
          maxWidth="xl"
          style={{
            marginTop: "20px",
            paddingLeft: isMdUp ? "230px" : "16px",
            paddingRight: "16px",
            maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
          }}
        >
    <Typography variant="h4" sx={{ mb: 3 }}>Todos os Produtos</Typography>

    {/* Barra de Ações */}
    <Grid container spacing={1} sx={{ mb: 2 }}>
      <Grid item xs={12} sm={2}>
        <Button variant="contained" color="success" startIcon={<AddIcon />}>
          Novo Produto
        </Button>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button variant="contained" color="primary">Calcular em Lote</Button>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<LocalOfferIcon />}
          onClick={() => handleAplicarDesconto(desconto)}
        >
          Aplicar Desconto
        </Button>
        <TextField
          type="number"
          label="Desconto (%)"
          value={desconto}
          onChange={(e) => setDesconto(e.target.value)}
          sx={{ mt: 1 }}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<PrintIcon />}
          onClick={() => gerarEtiquetasMultiplas(produtosSelecionados)}
          disabled={produtosSelecionados.length === 0}
        >
          Gerar Etiquetas
        </Button>
      </Grid>

      <Grid item xs={12} sm={2}>
        <Button
          variant="contained"
          color="warning"
          onClick={() => setExportModalOpen(true)} // Abre o modal para seleção de campos ao exportar
        >
          Exportar Estoque
        </Button>
      </Grid>
    </Grid>

    {/* Filtro por fornecedor */}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Select
                fullWidth
                value={filtro.fornecedor}
                onChange={(e) => setFiltro({ ...filtro, fornecedor: e.target.value })}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Fornecedor</em>
                </MenuItem>
                {fornecedores.map((fornecedor, index) => (
                  <MenuItem key={index} value={fornecedor}>
                    {fornecedor}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Buscar Descrição"
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
            </Grid>

            <Grid item xs={3}>
              <Select
                fullWidth
                value={filtro.categoria}
                onChange={(e) => setFiltro({ ...filtro, categoria: e.target.value })}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Categoria</em>
                </MenuItem>
                {categorias.map((categoria, index) => (
                  <MenuItem key={index} value={categoria}>
                    {categoria}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={3}>
              <Select
                fullWidth
                value={filtro.marca}
                onChange={(e) => setFiltro({ ...filtro, marca: e.target.value })}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Marca</em>
                </MenuItem>
                {marcas.map((marca, index) => (
                  <MenuItem key={index} value={marca}>
                    {marca}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Código da Peça"
                value={filtro.codigoPeca}
                onChange={(e) => setFiltro({ ...filtro, codigoPeca: e.target.value })}
              />
            </Grid>
          </Grid>


    {/* Resumos */}
    <Grid container spacing={2} sx={{ mb: 4 }}>
      <Grid item xs={12} sm={3}>
        <Box sx={{ bgcolor: "#1E88E5", color: "#fff", p: 2, textAlign: "center", borderRadius: 1 }}>
          <Typography variant="subtitle1">Produtos</Typography>
          <Typography variant="h5">{produtos.length}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box sx={{ bgcolor: "#43A047", color: "#fff", p: 2, textAlign: "center", borderRadius: 1 }}>
          <Typography variant="subtitle1">Valor de Venda</Typography>
          <Typography variant="h5">
            {produtos.reduce((total, produto) => {
              const valorVenda = parseFloat(produto.valorVenda) || 0;
              const quantidade = parseFloat(produto.quantidade) || 0;
              return total + (valorVenda * quantidade);
            }, 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Box sx={{ bgcolor: "#FDD835", color: "#fff", p: 2, textAlign: "center", borderRadius: 1 }}>
          <Typography variant="subtitle1">Estoque</Typography>
          <Typography variant="h5">
            {produtos.reduce((total, produto) => total + Number(produto.quantidade), 0)}
          </Typography>
        </Box>
      </Grid>
    </Grid>

    {/* Lista Virtualizada de Produtos */}
    <Box sx={{ mb: 4, maxWidth: "100%", overflowX: "auto" }}>
      <Typography variant="h6">Produtos</Typography>
      <Grid container spacing={2} sx={{ fontWeight: "bold", mb: 2 }}>
        <Grid item xs={1}>
          <FormControlLabel
            control={<Checkbox checked={selectAll} onChange={handleSelecionarTodos} />}
            label="Selecionar todos"
          />
        </Grid>
        <Grid item xs={3}>Produto</Grid>
        <Grid item xs={2.5}>Fornecedor</Grid>
        <Grid item xs={1}>Código</Grid>
        <Grid item xs={2}>Valor de Venda</Grid>
        <Grid item xs={1}>Estoque</Grid>
        <Grid item xs={1}>Ações</Grid>
      </Grid>
      <ListVirtualized height={400} itemCount={produtosFiltrados.length} itemSize={60} width="100%">
        {Row}
      </ListVirtualized>
    </Box>

    {renderModalEditarProduto()}
    {renderModalExport()}
  </Container>
);
};

export default GerenciarProdutos;
